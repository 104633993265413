import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getClient } from '../../api/clientService';
import { createPaymentLink } from '../../api/paymentInitiationService';
import Button from '../../components/atoms/Button/Button';
import Card from '../../components/atoms/Card/Card';
import MainHeader from '../../components/molecules/MainHeader/MainHeader';
import { useAuth } from '../../context/AuthContext';
import {
  CONSENT_SUCCESSFUL,
  CONSENT_UNSUCCESSFUL,
  PAYMENT_LINK_GENERATOR_PAGE
} from '../../routes/ConstantsRoutes';
import './PaymentGeneratorPage.scss';
const PaymentGeneratorPage = () => {
  let navigate = useNavigate();
  const { tokenInfo } = useAuth();

  const { isSuccess } = useQuery(
    ['getClientInfo'],
    async () => await getClient(tokenInfo?.clientId),
    {
      onSuccess: (data) => {
        setEnteredClientName(data.name);
        setEnteredCreditorIban(data?.accounts[0]?.iban);
        setEnteredCreditorBic(data?.accounts[0]?.bic);
        setAppId(data.appId);
      },
      onError: () => {},
      refetchOnWindowFocus: false
    }
  );

  const [enteredDescription, setEnteredDescription] = useState('Description');
  const [entredTokenDuration, setEntredTokenDuration] = useState('1800');
  const [enteredAmount, setEnteredAmount] = useState('2');
  const [enteredCurrency, setEnteredCurrency] = useState('EUR');
  const [enteredCreditorIban, setEnteredCreditorIban] = useState('FR7628233000013433912624405');
  const [enteredCreditorBic, setEnteredCreditorBic] = useState('REVOFRP2XXX');
  const [enteredSuccessfulReportUrl, setEnteredSuccessfulReportUrl] = useState(
    window.location.origin + CONSENT_SUCCESSFUL
  );
  const [enteredUnsuccessfulReportUrl, setEnteredUnsuccessfulReportUrl] = useState(
    window.location.origin + CONSENT_UNSUCCESSFUL
  );
  const [enteredClientName, setEnteredClientName] = useState('Client Name');
  const [appId, setAppId] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [enteredPsuName, setEnteredPsuName] = useState('PSU Name');
  const [enteredPsuEmail, setEnteredPsuEmail] = useState('psuemail@email.com');
  const [enteredPsuPhone, setEnteredPsuPhone] = useState('00000000');
  const [enteredPsuStreet, setEnteredPsuStreet] = useState('PSU Street');
  const [enteredPsuComplement, setEnteredPsuComplement] = useState('PSU Complement');
  const [enteredPsuNumber, setEnteredPsuNumber] = useState('999');
  const [enteredPsuCity, setEnteredPsuCity] = useState('PSU City');
  const [enteredPsuCountry, setEnteredPsuCountry] = useState('FR');
  const [enteredPsuZip, setEnteredPsuZip] = useState('00000000');
  const [errorconnection, setErrorConnection] = useState({
    show: false,
    message: 'Error initiating payment'
  });
  const [enteredEndToEnd, setEnteredEndToEnd] = useState(
    'HP7' + Math.floor(1000000000 + Math.random() * 9000000000)
  );
  const [enteredRemittanceInformation, setEnteredRemittanceInformation] = useState(
    'HP7 RM' + Math.round(new Date().getTime() / 1000)
  );
  const [enteredDepartmentCode, setEnteredDepartmentCode] = useState('');

  const descriptionChangeHandler = (event) => {
    setEnteredDescription(event.target.value);
  };

  const tokenDurationChangeHandler = (event) => {
    setEntredTokenDuration(event.target.value);
  };

  const amountChangeHandler = (event) => {
    setEnteredAmount(event.target.value);
  };

  const currencyChangeHandler = (event) => {
    setEnteredCurrency(event.target.value);
  };

  const creditorIbanHandler = (event) => {
    setEnteredCreditorIban(event.target.value);
  };

  const creditorBicHandler = (event) => {
    setEnteredCreditorBic(event.target.value);
  };
  const SuccessfulReportUrlChangeHandler = (event) => {
    setEnteredSuccessfulReportUrl(event.target.value);
  };

  const UnsuccessfulReportUrlChangeHandler = (event) => {
    setEnteredUnsuccessfulReportUrl(event.target.value);
  };

  const clientNameChangeHandler = (event) => {
    setEnteredClientName(event.target.value);
  };

  const typeVirementChangeHandler = () => {
    setIsChecked(!isChecked);
  };

  const psuNameChangeHandler = (event) => {
    setEnteredPsuName(event.target.value);
  };

  const psuEmailChangeHandler = (event) => {
    setEnteredPsuEmail(event.target.value);
  };

  const psuPhoneChangeHandler = (event) => {
    setEnteredPsuPhone(event.target.value);
  };

  const psuStreetChangeHandler = (event) => {
    setEnteredPsuStreet(event.target.value);
  };

  const psuComplementChangeHandler = (event) => {
    setEnteredPsuComplement(event.target.value);
  };

  const psuNumberChangeHandler = (event) => {
    setEnteredPsuNumber(event.target.value);
  };

  const psuCityChangeHandler = (event) => {
    setEnteredPsuCity(event.target.value);
  };

  const psuCountryChangeHandler = (event) => {
    setEnteredPsuCountry(event.target.value);
  };

  const psuZipChangeHandler = (event) => {
    setEnteredPsuZip(event.target.value);
  };

  const endToEndChangeHandler = (event) => {
    let value = event.target.value;
    setEnteredEndToEnd(value);
    highlightFieldByValue(value, 'endToEnd');
  };

  const remittanceInformationChangeHandler = (event) => {
    let value = event.target.value;
    setEnteredRemittanceInformation(value);
    highlightFieldByValue(value, 'remittanceInformation');
  };

  const departmentCodeChangeHandler = (event) => {
    setEnteredDepartmentCode(event.target.value);
  };

  const { mutate } = useMutation(
    ['auth'],
    async ({ paymentLinkRequest }) => await createPaymentLink(paymentLinkRequest),
    {
      onSuccess: (data) => {
        if (data?.resourceId !== undefined) {
          return navigate(`${PAYMENT_LINK_GENERATOR_PAGE}/${data.resourceId}`, { state: appId });
        }
      },
      onError: (e) => {
        const error = errorconnection;
        error.message =
          e.response.data.message === undefined ? e.response.data : 'Error initiating payment';
        error.show = true;
        setErrorConnection(error);
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  const submitHandler = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const paymentLinkRequest = {
        amount: enteredAmount,
        currency: enteredCurrency,
        description: enteredDescription,
        psuInfo: {
          city: enteredPsuCity,
          complement: enteredPsuComplement,
          country: enteredPsuCountry,
          email: enteredPsuEmail,
          name: enteredPsuName,
          number: enteredPsuNumber,
          phone: enteredPsuPhone,
          street: enteredPsuStreet,
          zip: enteredPsuZip
        },
        creditorAccount: {
          iban: enteredCreditorIban
        },
        creditorAgent: {
          bic: enteredCreditorBic
        },
        consentInfo: {
          expireIn: entredTokenDuration,
          unit: 'SECONDS'
        },
        successfulReportUrl: enteredSuccessfulReportUrl,
        unsuccessfulReportUrl: enteredUnsuccessfulReportUrl,
        localInstrument: isChecked ? 'INST' : null,
        endToEnd: enteredEndToEnd,
        remittanceInformation: enteredRemittanceInformation,
        departmentCode: enteredDepartmentCode
      };
      mutate({ paymentLinkRequest });
    }
  };

  const validateForm = () => {
    let isEnteredEndToEndValidate = false;
    let isEnteredRemittanceInformationValidate = false;

    if (enteredEndToEnd.length !== 0 && enteredEndToEnd.trim() !== '') {
      isEnteredEndToEndValidate = true;
    }

    if (enteredRemittanceInformation.length !== 0 && enteredRemittanceInformation.trim() !== '') {
      isEnteredRemittanceInformationValidate = true;
    }

    return isEnteredEndToEndValidate && isEnteredRemittanceInformationValidate;
  };

  const highlightField = (fieldId) => {
    document.getElementById(fieldId).style.borderColor = 'red';
  };

  const unhighlightField = (fieldId) => {
    document.getElementById(fieldId).style.borderColor = '';
  };

  const highlightFieldByValue = (fieldValue, fieldName) => {
    if (fieldValue.length === 0 || fieldValue.trim() === '') {
      highlightField(fieldName);
    } else {
      unhighlightField(fieldName);
    }
  };

  return (
    <React.Fragment>
      <MainHeader />
      <main>
        <Card className="form">
          <form onSubmit={submitHandler}>
            <h2 className="title">Payment Details</h2>
            <br />
            <div className="content">
              <div className="mainInfo">
                <div className="sectionLabel">
                  <text>SCA Info </text>
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="token-duration">SCA token Duration</label>
                  </div>
                  <input
                    type="text"
                    id="token-duration"
                    onChange={tokenDurationChangeHandler}
                    value={entredTokenDuration}
                  />
                </div>

                <div className="sectionLabel extra-space">
                  <text>Main Info </text>
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="instantPayment">Instant Payment </label>{' '}
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="typeVirement"
                      name="typeVirement"
                      checked={isChecked}
                      value={isChecked}
                      onChange={typeVirementChangeHandler}
                    />
                  </div>
                </div>

                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="amount">Amount</label>
                  </div>
                  <input
                    type="text"
                    id="amount"
                    onChange={amountChangeHandler}
                    value={enteredAmount}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    <label htmlFor="currency">Currency</label>
                  </div>
                  <input
                    type="text"
                    id="currency"
                    onChange={currencyChangeHandler}
                    value={enteredCurrency}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    <label htmlFor="creditor-iban">Creditor Iban</label>
                  </div>
                  <input
                    type="text"
                    id="creditor-iban"
                    onChange={creditorIbanHandler}
                    value={enteredCreditorIban}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    <label htmlFor="creditor-bic">Creditor Bic</label>
                  </div>
                  <input
                    type="text"
                    id="creditor-bic"
                    onChange={creditorBicHandler}
                    value={enteredCreditorBic}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="endToEnd">End To End</label>
                  </div>
                  <input
                    type="text"
                    id="endToEnd"
                    onChange={endToEndChangeHandler}
                    value={enteredEndToEnd}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="remittanceInformation">Remittance Information</label>
                  </div>
                  <input
                    type="text"
                    id="remittanceInformation"
                    onChange={remittanceInformationChangeHandler}
                    value={enteredRemittanceInformation}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    <label htmlFor="redirectURI">Successful report URL</label>
                  </div>
                  <input
                    type="text"
                    id="redirectURI"
                    onChange={SuccessfulReportUrlChangeHandler}
                    value={enteredSuccessfulReportUrl}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    <label htmlFor="redirectURI">Unsuccessful report URL</label>
                  </div>
                  <input
                    type="text"
                    id="redirectURI"
                    onChange={UnsuccessfulReportUrlChangeHandler}
                    value={enteredUnsuccessfulReportUrl}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="clientName">Client Name</label>
                  </div>
                  <input
                    type="text"
                    id="clientName"
                    onChange={clientNameChangeHandler}
                    value={enteredClientName}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    <label htmlFor="description">Description</label>
                  </div>
                  <textarea
                    rows="3"
                    id="description"
                    onChange={descriptionChangeHandler}
                    value={enteredDescription}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="departmentCode">Département</label>
                  </div>
                  <input
                    type="text"
                    id="departmentCode"
                    onChange={departmentCodeChangeHandler}
                    value={enteredDepartmentCode}
                  />
                </div>
              </div>
              <div className="panelPsu">
                <div className="sectionLabel">
                  <span>PSU Info</span>
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="psuName">Name</label>
                  </div>
                  <input
                    type="text"
                    id="psuName"
                    onChange={psuNameChangeHandler}
                    value={enteredPsuName}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="psuEmail">Email</label>
                  </div>
                  <input
                    type="text"
                    id="psuEmail"
                    onChange={psuEmailChangeHandler}
                    value={enteredPsuEmail}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="psuPhone">Phone</label>
                  </div>
                  <input
                    type="text"
                    id="psuPhone"
                    onChange={psuPhoneChangeHandler}
                    value={enteredPsuPhone}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="psuStreet">Street</label>
                  </div>
                  <input
                    type="text"
                    id="psuStreet"
                    onChange={psuStreetChangeHandler}
                    value={enteredPsuStreet}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="psuComplement">Complement</label>
                  </div>
                  <input
                    type="text"
                    id="psuComplement"
                    onChange={psuComplementChangeHandler}
                    value={enteredPsuComplement}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="psuNumber">Number</label>
                  </div>
                  <input
                    type="text"
                    id="psuNumber"
                    onChange={psuNumberChangeHandler}
                    value={enteredPsuNumber}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="psuCity">City</label>
                  </div>
                  <input
                    type="text"
                    id="psuCity"
                    onChange={psuCityChangeHandler}
                    value={enteredPsuCity}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="psuCountry">Country</label>
                  </div>
                  <input
                    type="text"
                    id="psuCountry"
                    onChange={psuCountryChangeHandler}
                    value={enteredPsuCountry}
                  />
                </div>
                <div className="control">
                  <div className="label">
                    {' '}
                    <label htmlFor="psuZip">Zip</label>
                  </div>
                  <input
                    type="text"
                    id="psuZip"
                    onChange={psuZipChangeHandler}
                    value={enteredPsuZip}
                  />
                </div>
              </div>
            </div>
            {errorconnection.show ? <p className={'error'}>{errorconnection.message}</p> : <p></p>}
            <div className="actions">
              <Button disabled={!isSuccess} type="submit" className="btn">
                Generate Payment Link
              </Button>
            </div>
          </form>
        </Card>
      </main>
    </React.Fragment>
  );
};

export default PaymentGeneratorPage;
