import moment from 'moment';
import React from 'react';
import { CguComponent } from '../Cgu/CguComponents';
import './ModalFooter.scss';

const ModalFooter = ({ client, paymentDetails, validationEnabled, submitPayment, pisp }) => {
  moment.locale('fr', {
    months:
      'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
        '_'
      ),
    monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_')
  });
  return (
    <div className="bpce-modal-footer">
      <div className="bpce-row row-center-footer">
        <div className="bpce-col-md-4 footer-size footer-padding">
          <p className="footer-row">
            <span className="footer-label">Bénéficiaire du virement :</span>
            <span className="footer-info">{paymentDetails?.creditor?.name}</span>
          </p>
          <hr className="footer-separator" />
          <p className="footer-row">
            <span className="footer-label">Montant</span>
            <span className="footer-info">
              {paymentDetails?.amount} €
              {paymentDetails?.requestedExecutionDate && (
                <span className="footer-info-red">
                  &nbsp;le&nbsp;
                  {moment(paymentDetails?.requestedExecutionDate, 'YYYY-MM-DD HH:mm:ss').format(
                    'DD MMMM YYYY'
                  )}
                </span>
              )}
            </span>
          </p>
        </div>
        <div className="bpce-col-md-4 footer-size footer-padding">
          <CguComponent
            appId={client?.appId}
            pisp={pisp}
            validationEnabled={validationEnabled}
            submitPayment={submitPayment}
          ></CguComponent>
        </div>
      </div>
    </div>
  );
};

export default ModalFooter;
