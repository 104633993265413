import QueryString from 'qs';
import axiosInstance from '../util/AxiosInterceptor';
import { DASHBOARD_DETAILS_URL, DASHBOARD_PAYMENT_LINK_URL, DASHBOARD_URL } from './ConstantsURL';

export const getsAllPaymentInitiations = async (filter, pageable) => {
  if (filter.formatAmount) {
    filter.formatAmount[0] = filter.formatAmount[0] === undefined ? '0' : filter.formatAmount[0];
  }
  const { data } = await axiosInstance.get(DASHBOARD_URL, {
    params: {
      ...filter,
      page: pageable.pageNumber,
      size: pageable.pageSize,
      sort: `${pageable.sort.id},${pageable.sort.desc ? 'desc' : 'asc'}`
    },
    paramsSerializer: (params) => {
      return QueryString.stringify(params, { arrayFormat: 'repeat' });
    }
  });
  return data;
};

export const getPaymentInitiation = async (resourceId) => {
  if (resourceId) {
    const { data } = await axiosInstance.get(`${DASHBOARD_DETAILS_URL}/${resourceId}`);
    return data;
  }
  return {
    stepStatusArray: ['', '', '', '']
  };
};

const sortMap = {
  creationDateTime: 'createdAt',
  status: 'consentStatus',
  type: 'paymentDetails.localInstrument',
  resourceIdLink: 'hubResourceId',
  creationDateLink: 'createdAt',
  resourceIdInitiation: 'resourceId',
  creationDateInitiation: 'paymentDetails.requestedExecutionDate',
  debtorBic: 'paymentDetails.debtorAgent',
  amount: 'paymentDetails.amount',
  department: 'paymentDetails.departmentCode'
};

export const getsAllPaymentLinks = async (filter, pageable) => {
  if (filter.amount) {
    filter.amount[0] = filter.amount[0] === undefined ? '0' : filter.amount[0];
  }
  const { data } = await axiosInstance.get(DASHBOARD_PAYMENT_LINK_URL, {
    params: {
      ...filter,
      page: pageable.pageNumber,
      size: pageable.pageSize,
      sort: `${sortMap[pageable.sort.id]},${pageable.sort.desc ? 'desc' : 'asc'}`
    },
    paramsSerializer: (params) => {
      return QueryString.stringify(params, { arrayFormat: 'repeat' });
    }
  });
  return data;
};
