import React from 'react';
import '../../../assets/scss/card/src/index.scss';
import '../../../assets/scss/tile/src/index.scss';

const BankCard = (props) => {
  let logo = props.logo;
  const bankSelectionHandler = () => {
    props.setSelectedBank(props.id);
  };

  return (
    <li onClick={bankSelectionHandler} className="bpce-card-group">
      <div className={`bpce-tile bpce-card-bankCard tile-margin ${props.styleName}`}>
        {logo != null ? (
          <img
            className="bpce-card-bankCard-icon"
            src={`data:image/svg+xml;base64,${logo}`}
            alt="logo"
          />
        ) : (
          <div className="bpce-card-no-img-load" />
        )}
        <span className="bpce-card-bankCard-label">{props.name}</span>
      </div>
    </li>
  );
};

export default BankCard;
