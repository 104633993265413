import React from 'react';
import rejected from '../../assets/icons/png/rejected.png';
import Card from '../../components/atoms/Card/Card';
import MainHeader from '../../components/molecules/MainHeader/MainHeader';
import './Executed.scss';
const Unsuccessful = () => {
  return (
    <React.Fragment>
      <MainHeader />
      <main>
        <Card className="container">
          <img className="logo" src={rejected} alt="approved" />
          <span className="message">Link already executed or expired!</span>
        </Card>
      </main>
    </React.Fragment>
  );
};

export default Unsuccessful;
