import React from 'react';

import SearchField from '../../atoms/SearchField/SearchField';
import BankList from '../../molecules/BankList/BankList';
import './PanelChoose.scss';
export default function PanelChoose(props) {
  return (
    <div className="bpce-row row-center">
      <div>
        <div className="searchField-label">Depuis quelle banque souhaitez-vous payer ?</div>
        <SearchField setBankName={props.setBankName} bankName={props.bankName} />
        <BankList
          items={props.banks}
          setSelectedBank={props.setSelectedBank}
          selectedId={props.selectedId}
        />
      </div>
    </div>
  );
}
