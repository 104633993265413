import jwt_decode from 'jwt-decode';
import * as R from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGIN_PAGE } from '../routes/ConstantsRoutes';

//Auth Context
const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const loadAuthInfo = () => {
    const storedAuthInfo = JSON.parse(localStorage.getItem('AuthenticationInfo'));
    if (storedAuthInfo === null) {
      return {
        isLoggedIn: false,
        authMethod: null
      };
    }
    return storedAuthInfo;
  };

  const loadTokenInfo = () => {
    const storedTokenInfo = JSON.parse(localStorage.getItem('TokenInfo'));
    if (storedTokenInfo === null) {
      return {
        token: null,
        tokenExpiration: null,
        clientId: null,
        userId: null,
        role: null
      };
    }
    return storedTokenInfo;
  };

  const loadUserInfo = () => {
    const storedUserInfo = JSON.parse(localStorage.getItem('UserInfo'));
    if (storedUserInfo === null) {
      return {
        firstName: null,
        lastName: null,
        email: null,
        status: null,
        actionsRequired: null
      };
    }
    return storedUserInfo;
  };

  const [authenticationInfo, setAuthenticatationInfo] = useState(loadAuthInfo());
  const [userInfo, setUserInfo] = useState(loadUserInfo());
  const [tokenInfo, setTokenInfo] = useState(loadTokenInfo());

  useEffect(() => {
    if (!R.equals(authenticationInfo, loadAuthInfo())) {
      setAuthenticatationInfo(loadAuthInfo());
    }
    if (!R.equals(tokenInfo !== loadTokenInfo())) {
      setTokenInfo(loadTokenInfo());
    }
    if (!R.equals(userInfo !== loadUserInfo())) {
      setUserInfo(loadUserInfo());
    }
  }, [tokenInfo, userInfo, authenticationInfo]);

  const navigate = useNavigate();

  const loginHandlerGAAP = () => {
    localStorage.setItem(
      'AuthenticationInfo',
      JSON.stringify({
        isLoggedIn: true,
        authMethod: 'GAAP'
      })
    );
    setAuthenticatationInfo(loadAuthInfo());
  };

  const loginHandlerJWT = () => {
    localStorage.setItem(
      'AuthenticationInfo',
      JSON.stringify({
        isLoggedIn: true,
        authMethod: 'JWT'
      })
    );
    setAuthenticatationInfo(loadAuthInfo());
  };

  const storeJWTInfo = (dataAuth) => {
    const jwtDecoded = jwt_decode(dataAuth.token);
    localStorage.setItem(
      'TokenInfo',
      JSON.stringify({
        token: dataAuth.token,
        tokenExpiration: dataAuth.expirationDate,
        clientId: jwtDecoded.clientId,
        userId: jwtDecoded.userId,
        role: jwtDecoded.auth
      })
    );
    setTokenInfo(loadTokenInfo());
  };

  const storeGaapInfo = (dataAuth) => {
    localStorage.setItem(
      'TokenInfo',
      JSON.stringify({
        clientId: dataAuth.clientId,
        userId: dataAuth.userId,
        role: dataAuth.auth,
        username: dataAuth.username
      })
    );
    setTokenInfo(loadTokenInfo());
  };

  const storeUserInfo = (dataUser) => {
    localStorage.setItem(
      'UserInfo',
      JSON.stringify({
        firstName: dataUser.firstName,
        lastName: dataUser.lastName,
        email: dataUser.email,
        status: dataUser.status,
        role: dataUser.role,
        actionsRequired: dataUser.actionsRequired
      })
    );
    setUserInfo(loadUserInfo());
  };

  const logoutHandler = async () => {
    localStorage.clear();
    setAuthenticatationInfo(loadAuthInfo());
    setTokenInfo(loadTokenInfo());
    setUserInfo(loadUserInfo());
    navigate(LOGIN_PAGE);
  };

  return (
    <AuthContext.Provider
      value={{
        tokenInfo,
        userInfo,
        authenticationInfo,
        storeUserInfo,
        storeGaapInfo,
        storeJWTInfo,
        loginHandlerGAAP,
        loginHandlerJWT,
        logoutHandler
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
