import React from 'react';
import { PispLogo } from '../PispLogo/PispLogo';

const ModalHeader = ({
  clientImage,
  onCloseRedirect,
  tabValue,
  goBack,
  pispLogo,
  isLoadingClient,
  isLoadingPisp
}) => {
  //to Modify
  function hideModal(url = 'https://google.com') {
    window.location.href.replace(url);
  }

  return (
    <div
      className="bpce-modal-header"
      style={{ height: '180px', border: '0px', paddingBottom: '0px' }}
    >
      <div
        className="bpce-header bpce-header-with-border-bottom"
        style={{ height: '100%', borderWidth: '0px' }}
      >
        <div className="bpce-header-left">
          {tabValue > 0 && (
            <button
              type="button"
              className="bpce-button bpce-button-secondary bpce-button-icon-only bpce-button-no-border"
              aria-label="Bouton de retour"
              onClick={goBack}
            >
              <span className="bpce-icon-font chevron-left"></span>
              <span className="bpce-header-back-label">Retour</span>
            </button>
          )}
        </div>
        <div
          className="bpce-header-middle"
          style={{
            width: '668px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div
            className="bpce-header-ellipsis-container display-flex-row "
            style={{
              width: '10rem',
              height: '80px',
              marginRight: 'auto'
            }}
          >
            {!isLoadingClient && (
              <img
                alt="client logo"
                className="img-title"
                src={`data:image/svg+xml;base64,${clientImage}`}
                style={{ width: '85%' }}
              />
            )}
            {isLoadingClient && <span>Loading client image ...</span>}
          </div>
          <div
            style={{
              width: '668px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                fontStyle: 'italic',
                fontFamily: 'Calibri'
              }}
            >
              L’initiation de paiement par virement est effectuée par
            </span>
            {!isLoadingPisp && <PispLogo logo={pispLogo}></PispLogo>}
            {(isLoadingPisp || (!isLoadingPisp && pispLogo === undefined)) && (
              <span style={{ height: '55px' }}>Loading Pisp image ...</span>
            )}
          </div>
        </div>
        <div className="bpce-header-right" style={{ alignItems: 'flex-start' }}>
          <button
            type="button"
            className="bpce-button bpce-button-secondary bpce-button-icon-only bpce-button-no-border bpce-header-close"
            aria-label="Bouton de fermeture"
            onClick={() => hideModal(onCloseRedirect)}
          >
            <span className="bpce-icon-font close"></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalHeader;
