export const AUTHENTICATE_BY_GAAP_URL = 'auth/authenticateGaap';
export const AUTHENTICATE_BY_JWT_URL = 'auth/authenticateJwt';
export const AUTHENTICATION_LOGOUT_URL = 'auth/logout';
export const AUTHENTICATION_REFRESH_URL = 'auth/refreshJwtToken';
export const CLIENT_URL = 'clients';
export const USER_URL = 'users';
export const CONSENT_URL = 'consent';
export const CONSENT_WEBCONNECT_URL = 'consent/web-connect';
export const DASHBOARD_URL = 'dashboard';
export const DASHBOARD_PAYMENT_LINK_URL = 'dashboard/payment-link';
export const DASHBOARD_DETAILS_URL = 'dashboard/detail';
export const PAYMENT_LINK_WEB_URL = 'payment-link/web';
export const PISP_URL = 'pisp';
