import React from 'react';
//import "./Payment.css";
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { retrieveAspsp } from '../../api/consentService';
import Modal from '../../components/organism/Modal/Modal';
import { CONSENT_EXEC_EXPIRED } from '../../routes/ConstantsRoutes';

const BanksPage = () => {
  const navigate = useNavigate();
  const { resourceId } = useParams();

  const {
    data: consentInfo,
    isLoading,
    isFetching
  } = useQuery(['getBanks', resourceId], async () => await retrieveAspsp(resourceId), {
    onSuccess: (data) => {
      return data;
    },
    onError: () => {
      navigate(CONSENT_EXEC_EXPIRED);
    },
    enabled: true,
    refetchOnWindowFocus: false,
    retry: 1
  });

  return (
    <React.Fragment>
      <Modal
        banks={consentInfo?.aspspList}
        client={consentInfo?.client}
        paymentDetails={consentInfo?.paymentDetails}
        resourceId={resourceId}
        isLoadingClient={isLoading || isFetching}
      />
    </React.Fragment>
  );
};

export default BanksPage;
