import { useQuery } from 'react-query';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { getActivatedBanks } from '../../api/clientService';
import Header from '../../components/molecules/HeaderDashBoard/HeaderDashBoard';
import { useAuth } from '../../context/AuthContext';

export default function ActivatedBanksPage() {
  const { logoutHandler, tokenInfo } = useAuth();
  return <Page logoutHandler={() => logoutHandler()} clientId={tokenInfo.clientId} />;
}

const Page = (props) => {
  const [banks, setBanks] = useState([]);
  const [banksFilter, setBanksFilter] = useState([]);
  const [layout, setLayout] = useState('headCompany');
  const [headCompany, setHeadCompany] = useState([]);

  useEffect(() => {
    setBanksFilter(banks);
  }, [banks]);

  useEffect(() => {
    let filtered = banks;
    if (headCompany) {
      filtered = filtered.filter((bank) => bank.name === headCompany);
    }
    setBanksFilter(filtered);
  }, [headCompany]);

  const { isLoading, error, isFetching } = useQuery(
    ['getActivatedBanks'],
    async () => await getActivatedBanks(props.clientId),
    {
      onSuccess: (data) => {
        setBanks(data);
      },
      enabled: true,
      refetchOnWindowFocus: false
    }
  );

  if (isLoading || isFetching) {
    return (
      <div className="loading-screen">
        <div className="loading-filter" />
        <Header title="Banques en activité" />
        <div className="body-margin">
          <div className="table-loader">
            <svg
              className="bpce-loader-svg"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <circle cx="50" cy="50" r="45" />
            </svg>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <>
        <Header
          onLogout={() => props.logoutHandler()}
          activePage="activated-banks"
          title="ASPSP accessibles"
        />
        <div className="body-margin">An error has occurred: {error.message}</div>
      </>
    );
  }

  return (
    <>
      <Header
        onLogout={() => props.logoutHandler()}
        activePage="activated-banks"
        title="ASPSP accessibles"
      />
      <div className="body-margin">
        <Grid container spacing={2}>
          <Grid item sm={6} md={4} lg={3} xl={2}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
              <InputLabel id="layout-select-label">Groupe en</InputLabel>
              <Select
                labelId="layout-select-label"
                id="layout-select"
                value={layout}
                label="Groupe en"
                onChange={(event) => setLayout(event.target.value)}
              >
                <MenuItem value={'headCompany'}>Head Company</MenuItem>
                <MenuItem value={'aspsps'}>ASPSP</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={6} md={4} lg={3} xl={2}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
              <InputLabel id="head-company-select-label">Head Company</InputLabel>
              <Select
                labelId="head-company-select-label"
                id="head-company-select"
                value={headCompany}
                label="Head Company"
                onChange={(event) => setHeadCompany(event.target.value)}
              >
                <MenuItem value="">
                  <em>Tout</em>
                </MenuItem>
                {banks.map((bank, index) => (
                  <MenuItem key={index} value={bank.name}>
                    {bank.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {layout === 'headCompany' && (
          <HeadCompany
            banks={banksFilter}
            selectBank={(value) => {
              setHeadCompany(value);
              setLayout('aspsps');
            }}
          />
        )}
        {layout === 'aspsps' && <Aspsps banks={banksFilter} />}
      </div>
    </>
  );
};

const HeadCompany = ({ banks, selectBank }) => {
  return (
    <Grid container spacing={2} paddingTop={5} paddingBottom={5}>
      {banks.map((bank, index) => {
        return (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Card
              variant="outlined"
              style={{ minHeight: '90px', cursor: 'pointer' }}
              onClick={() => selectBank(bank.name)}
            >
              <CardHeader
                avatar={<Avatar src={`data:image/svg+xml;base64,${bank.logo}`}></Avatar>}
                title={bank.name}
                subheader={bank.aspsps.length + ' ASPSP' + (bank.aspsps.length > 1 ? 's' : '')}
              ></CardHeader>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

const Aspsps = ({ banks }) => {
  const numberFormat = (value) =>
    new Intl.NumberFormat('fr', {
      style: 'currency',
      currency: 'EUR'
    }).format(value);

  return (
    <Grid container spacing={2} paddingTop={5} paddingBottom={5}>
      {banks.map((bank) =>
        bank.aspsps.map((aspsp, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Card style={{ minHeight: '460px' }}>
              <Tooltip title={`${aspsp.name}`}>
                <div>
                  <CardHeader
                    avatar={<Avatar src={`data:image/svg+xml;base64, ${aspsp.logo}`}></Avatar>}
                    title={
                      aspsp.name.length > 15 ? `${aspsp.name.substring(0, 12)}...` : aspsp.name
                    }
                    subheader={bank.name}
                  ></CardHeader>
                </div>
              </Tooltip>
              <CardContent>
                <div>
                  <Typography>Type:</Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {aspsp.paymentType ? aspsp.paymentType : 'N/D'}
                  </Typography>
                </div>

                <div>
                  <Typography>Limite de montant:</Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {aspsp.paymentAmountLimit ? numberFormat(aspsp.paymentAmountLimit) : 'N/D'}
                  </Typography>
                </div>

                <div>
                  <Typography>Approche:</Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {aspsp.approach ? aspsp.approach.join(', ') : 'N/D'}
                  </Typography>
                </div>

                <div>
                  <Typography>BIC:</Typography>
                  {aspsp.bics && aspsp.bics.length > 1 ? (
                    <Accordion
                      disableGutters
                      elevation={0}
                      sx={{
                        '&:before': {
                          display: 'none',
                          margin: 'auto'
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-bic"
                        id="panel-bic"
                        sx={{ textAlign: 'left', alignItems: 'left', padding: '0px' }}
                      >
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {`${aspsp.bics.slice(0, 1).join(', ')} ...`}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {aspsp.bics.join(', ')}
                          </Typography>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {aspsp.bics.length > 0 ? aspsp.bics.join(', ') : 'N/D'}
                    </Typography>
                  )}
                </div>

                <div>
                  <Typography>IID:</Typography>
                  {aspsp.iids && aspsp.iids.length > 1 ? (
                    <Accordion
                      disableGutters
                      elevation={0}
                      sx={{
                        '&:before': {
                          display: 'none',
                          margin: 'auto'
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-iid"
                        id="panel-iid"
                        sx={{ textAlign: 'left', alignItems: 'left', padding: '0px' }}
                      >
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {`${aspsp.iids.slice(0, 1).join(', ')} ...`}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {aspsp.iids.join(', ')}
                          </Typography>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {aspsp.iids.length > 0 ? aspsp.iids.join(', ') : 'N/D'}
                    </Typography>
                  )}
                </div>

                <div>
                  <Typography>Niveau de service:</Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {aspsp.serviceLevel ? aspsp.serviceLevel : 'N/D'}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))
      )}
    </Grid>
  );
};
