import { useQuery } from 'react-query';
import { getClient } from '../../api/clientService';
import Header from '../../components/molecules/HeaderDashBoard/HeaderDashBoard';
import { useAuth } from '../../context/AuthContext';
import ClientComponent from './ClientComponent';

export default function SettingsPage() {
  const { logoutHandler, tokenInfo } = useAuth();
  return <ClientFunction logoutHandler={() => logoutHandler()} clientId={tokenInfo.clientId} />;
}

function ClientFunction(props) {
  const { isLoading, error, data, isFetching } = useQuery(
    ['getClient'],
    async () => await getClient(props.clientId),
    {
      onSuccess: (data) => {
        return data;
      },
      enabled: true,
      refetchOnWindowFocus: false
    }
  );

  if (isLoading || isFetching)
    return (
      <div className="loading-screen">
        <div className="loading-filter" />
        <Header title="Modifier les paramètres" />
        <div className="body-margin"></div>
      </div>
    );

  if (error)
    return (
      <div>
        <Header
          onLogout={() => props.logoutHandler()}
          activePage="settings"
          title="Modifier les paramètres"
        />
        <div className="body-margin">An error has occurred: {error.message}</div>
      </div>
    );

  return (
    <div>
      <Header
        onLogout={() => props.logoutHandler()}
        activePage="settings"
        title="Modifier les paramètres"
      />
      <div className="body-margin">
        <ClientComponent client={data}></ClientComponent>
      </div>
    </div>
  );
}
