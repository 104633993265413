const algorithmName = 'AES-CBC';

export const encryptData = async (data, secretKey) => {
  const encodedData = new TextEncoder().encode(data);
  const key = await importKey(secretKey);
  const iv = window.crypto.getRandomValues(new Uint8Array(16));

  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: algorithmName,
      iv: iv
    },
    key,
    encodedData
  );

  const combined = new Uint8Array(iv.length + encryptedData.byteLength);
  combined.set(new Uint8Array(encryptedData), iv.length);
  combined.set(iv, 0);

  return convertToBase64String(combined);
};

async function importKey(secretKey) {
  const keyData = Uint8Array.from(window.atob(secretKey), (c) => c.charCodeAt(0));
  return window.crypto.subtle.importKey('raw', keyData, { name: algorithmName }, false, [
    'encrypt'
  ]);
}

function convertToBase64String(data) {
  return window.btoa(String.fromCharCode.apply(null, data));
}
