import axiosInstance from '../util/AxiosInterceptor';
import { USER_URL } from './ConstantsURL';

export const updatePassword = async (idUser, currentPassword, newPassword) => {
  const payload = {
    newPassword,
    currentPassword
  };
  const { data } = await axiosInstance.put(`${USER_URL}/${idUser}/password`, payload);
  return data;
};

export const getUserByIdentification = async (identification) => {
  const { data } = await axiosInstance.get(`${USER_URL}/${identification}/identification`);
  return data;
};

export const getUser = async (idUser) => {
  const { data } = await axiosInstance.get(`${USER_URL}/${idUser}`);
  return data;
};
