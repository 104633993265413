import React from 'react';
import { ToastContainer } from 'react-toastify';
import './assets/css/App.css';
import RouteConfig from './routes/RouteConfig';

function App() {
  return (
    <div>
      <RouteConfig />;
      <ToastContainer />
    </div>
  );
}

export default App;
