import React from 'react';

const PispLogo = ({ logo }) => {
  if (logo) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
          height: '5rem'
        }}
      >
        <img
          alt="pisp logo"
          style={{
            display: 'block',
            marginTop: '5px',
            maxWidth: '150px',
            height: '100%'
          }}
          src={`data:image/svg+xml;base64,${logo}`}
        />
      </div>
    );
  }
  return null;
};

export { PispLogo };
