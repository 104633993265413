import { config } from '../config';
import axiosInstance from '../util/AxiosInterceptor';
import { encryptData } from '../util/EncryptUtil';
import {
  AUTHENTICATE_BY_GAAP_URL,
  AUTHENTICATE_BY_JWT_URL,
  AUTHENTICATION_LOGOUT_URL
} from './ConstantsURL';

export const getAuthenticationJwt = async (identification, password) => {
  const encryptedIdentification =
    config.level === 'dev'
      ? identification
      : await encryptData(identification, `${config.secretKeyEncrypt}`);

  const encryptedPassword =
    config.level === 'dev' ? password : await encryptData(password, `${config.secretKeyEncrypt}`);

  const authentication = {
    identification: encryptedIdentification,
    password: encryptedPassword
  };

  const { data } = await axiosInstance.post(AUTHENTICATE_BY_JWT_URL, authentication);
  return data;
};

export const getAuthenticationGaap = async () => {
  const { data } = await axiosInstance.get(AUTHENTICATE_BY_GAAP_URL);
  return data;
};

export const getLogout = async () => {
  const { data } = await axiosInstance.get(AUTHENTICATION_LOGOUT_URL);
  return data;
};
