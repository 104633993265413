import React from 'react';
import approved from '../../assets/icons/png/approved.png';
import Card from '../../components/atoms/Card/Card';
import MainHeader from '../../components/molecules/MainHeader/MainHeader';
import './Successful.scss';
const Successful = () => {
  return (
    <React.Fragment>
      <MainHeader />
      <main>
        <Card className="container">
          <img className="logo" src={approved} alt="approved" />
          <span className="message">Payment is validated!</span>
        </Card>
      </main>
    </React.Fragment>
  );
};

export default Successful;
