import axiosInstance from '../util/AxiosInterceptor';
import { CLIENT_URL } from './ConstantsURL';

export const patchClient = async (id, payload) => {
  const { data } = await axiosInstance.patch(`${CLIENT_URL}/${id}`, payload);
  return data;
};

export const getClient = async (id) => {
  const { data } = await axiosInstance.get(`${CLIENT_URL}/${id}`);
  return data;
};

export const getActivatedBanks = async (id) => {
  const { data } = await axiosInstance.get(`${CLIENT_URL}/${id}/activated-banks`);
  return data;
};
