// Function to format a string so that every word in an expression starts with UpperCase
export const formatString = (text) => {
  return text.toLowerCase().replace(/(^|\s)\S/g, function (char) {
    return char.toUpperCase();
  });
};

// Function to format a number (amount) according to the french convention
export const formatNumberFrenchConvention = (number) => {
  return number.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};
