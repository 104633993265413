import React, { useState } from 'react';
import CreationDateFilter from '../../atoms/Filter/CreationDateFilter';
import InputFilter from '../../atoms/Filter/InputFilter';
import MontantFilter from '../../atoms/Filter/MontantFilter';
import PaymentTypeFilter from '../../atoms/Filter/PaymentTypeFilter';
import StatusFilter from '../../atoms/Filter/StatusFilter';

export default function FiltersPaymentLinkList({ setFilter, isFetching }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters] = useState(new Map());
  let intervalId;

  const filter = (name, value) => {
    if (filters.has(name)) {
      filters.delete(name);
    }
    filters.set(name, value);
    const obj = new Object();
    filters.forEach((value, key) => (obj[key] = value));
    setFilter(obj);
  };

  const performSeek = (name, value) => {
    let counter = 1;
    if (intervalId) clearInterval(intervalId);
    intervalId = setInterval(() => {
      counter = counter - 1;
      if (counter === 0) {
        filter(name, value);
        clearInterval(intervalId);
      }
    }, 1000);
  };

  const filterMapper = (filters) => {
    let codes = [];
    filters.forEach((element) => {
      switch (element) {
        case 'REJETÉ':
          codes.push('EXPIRED');
          break;
        case 'EN COURS':
          codes.push('PENDING');
          break;
        case 'EXÉCUTÉ':
          codes.push('PROCESSED');
          codes.push('EXECUTED');
          break;
      }
    });
    return codes;
  };

  return (
    <div>
      <h3 className="filters-title">Filtrez vos lien de paiement par :</h3>
      <div className="filters">
        <StatusFilter
          label="Etat du lien"
          setFilter={(name, filters) => {
            filter(name, filterMapper(filters));
          }}
          name="status"
          isFilterOpen={isFilterOpen}
          isFetching={isFetching}
          setIsFilterOpen={setIsFilterOpen}
        />
        <PaymentTypeFilter
          label="Type de lien"
          setFilter={(name, value) => {
            filter(
              name,
              value.map((item) => (item === 'IP' ? 'INST' : item))
            );
          }}
          name="type"
          isFilterOpen={isFilterOpen}
          isFetching={isFetching}
          setIsFilterOpen={setIsFilterOpen}
        />
        <InputFilter
          label="Ressource ID Lien"
          setFilter={performSeek}
          name="resourceIdLink"
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
        <InputFilter
          label="Ressource ID Initiation"
          setFilter={performSeek}
          name="resourceIdInitiation"
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
        <CreationDateFilter
          label="Date création Initiation"
          setFilter={performSeek}
          name="creationDateInitiation"
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
        <InputFilter
          label="BIC débiteur Initiation"
          setFilter={performSeek}
          name="debtorBic"
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
        <MontantFilter
          label="Montant"
          setFilter={performSeek}
          name="amount"
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
        <InputFilter
          label="Département"
          setFilter={performSeek}
          name="department"
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
      </div>
    </div>
  );
}
