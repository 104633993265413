import fr from 'date-fns/locale/fr';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../assets/scss/chip/src/index.scss';
import '../../../assets/scss/loader/src/index.scss';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import './CreationDateFilter.scss';

export default function CreationDateFilter({
  label,
  setFilter,
  name,
  isFilterOpen,
  setIsFilterOpen,
  width = 230,
  dateFormat = 'YYYY-MM-DD'
}) {
  const LogoInput = React.forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="bpce-datepicker-button filter" onClick={onClick} ref={ref}>
      <div>{value}</div>
      <div className="bpce-datepicker-logo bpce-icon-font calendar"></div>
    </button>
  ));

  registerLocale('fr', fr);
  const MIN = 'minDate';
  const MAX = 'maxDate';

  const maxibulle = useRef();
  const buttonRef = useRef();
  const currentFilter = useRef();
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();

  function openMaxibulle() {
    buttonRef.current.classList.toggle('bpce-chip-active');
    buttonRef.current.setAttribute('aria-expanded', 'true');
    maxibulle.current.setAttribute('data-show', '');
    setIsFilterOpen(true);
  }

  function closeMaxibulle() {
    if (buttonRef.current.classList.contains('bpce-chip-active')) {
      buttonRef.current.classList.remove('bpce-chip-active');
      buttonRef.current.setAttribute('aria-expanded', 'false');
      maxibulle.current.removeAttribute('data-show');
      setIsFilterOpen(false);
    }
  }

  useOnClickOutside(maxibulle, buttonRef, () => {
    closeMaxibulle();
  });

  const updateFilter = (inputName, value) => {
    let array = [];
    if (inputName === MIN) {
      setMinValue(value);
      if (value) {
        array.push(
          inputName === MIN ? moment(value).format(dateFormat) : moment(minValue).format(dateFormat)
        );
      }
    } else {
      setMaxValue(value);
      if (value) {
        array.push(
          inputName === MAX ? moment(value).format(dateFormat) : moment(maxValue).format(dateFormat)
        );
      }
    }

    const even = (element) => element !== undefined && element.trim() !== '';
    const hasFilter = array.some(even);
    if (hasFilter && !buttonRef.current.classList.contains('bpce-chip-selected')) {
      buttonRef.current.classList.toggle('bpce-chip-selected');
    } else if (!hasFilter) {
      buttonRef.current.classList.remove('bpce-chip-selected');
    }
    setFilter(name, array);
  };

  return (
    <div ref={currentFilter}>
      <button
        ref={buttonRef}
        id="chipActionPanel"
        className="bpce-chip"
        aria-describedby="maxibulle-1"
        aria-expanded="false"
        onClick={() => {
          if (buttonRef.current.classList.contains('bpce-chip-active')) {
            closeMaxibulle();
          } else if (isFilterOpen === false) {
            openMaxibulle();
          }
        }}
      >
        <span className="bpce-chip-label">{label}</span>
      </button>
      <div
        id="maxibulle-1"
        className="bpce-maxibulle"
        role="tooltip"
        placement="bottom-end"
        ref={maxibulle}
        style={{ width: `${width + 150}px`, display: 'flex' }}
      >
        <DatePicker
          onChange={(date) => updateFilter(MIN, date)}
          locale={fr}
          selected={minValue}
          dateFormat="dd-MM-yyyy"
          placeholderText="dd-MM-yyyy"
          selectsStart
          customInput={<LogoInput />}
          disabledKeyboardNavigation
          isClearable={true}
        />
        <label
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          to
        </label>
        <DatePicker
          id="end-date-range"
          onChange={(date) => updateFilter(MAX, date)}
          locale={fr}
          selected={maxValue}
          dateFormat="dd-MM-yyyy"
          placeholderText="dd-MM-yyyy"
          selectsEnd
          minDate={minValue}
          customInput={<LogoInput />}
          disabledKeyboardNavigation
          isClearable={true}
        />
      </div>
    </div>
  );
}
