import React from 'react';
import ReactPaginate from 'react-paginate';
import './pagination.scss';
export default function Pagination({ gotoPage, pageCount, forcePage }) {
  const handlePageClick = (event) => {
    gotoPage(event.selected);
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="pagination-container"
        pageClassName="pagination-item"
        previousLinkClassName="disabledClassName"
        nextLinkClassName="disabledClassName"
        forcePage={forcePage}
        pageLinkClassName="click-area"
      />
    </>
  );
}
