import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';

export default function Header(props) {
  const DASHBOARD = 'dashboard';
  const SETTINGS = 'settings';
  const ACTIVATED_BANKS = 'activated-banks';
  const PAYMENT_LINK = 'payment-link';
  const { userInfo } = useAuth();
  let navigate = useNavigate();

  const [dashboardIsActive, setDashboardIsActive] = useState(
    props.activePage === DASHBOARD ? true : false
  );
  const [settingsIsActive, setSettingsIsActive] = useState(
    props.activePage === SETTINGS ? true : false
  );
  const [activatedBanksIsActive, setActivatedBanksIsActive] = useState(
    props.activePage === ACTIVATED_BANKS ? true : false
  );
  const [paymentLinkIsActive, setPaymentLinkIsActive] = useState(
    props.activePage === PAYMENT_LINK ? true : false
  );

  const handleLogout = () => {
    props.onLogout();
  };

  const handleChangeTab = (path) => {
    setDashboardIsActive(path === DASHBOARD);
    setSettingsIsActive(path === SETTINGS);
    setActivatedBanksIsActive(path === ACTIVATED_BANKS);
    setPaymentLinkIsActive(path === PAYMENT_LINK);
    navigate(`/${path}`);
  };

  const shouldShowMenu = () => {
    return userInfo.role === 'ADMIN' || userInfo.role === 'USER_ADMIN';
  };

  const shouldShowAspspTabInMenu = () => {
    return (
      userInfo.role === 'ADMIN' ||
      userInfo.role === 'USER_ADMIN' ||
      userInfo.role === 'STANDARD_USER'
    );
  };

  return (
    <header>
      <div className="bpce-menubar">
        <button className="bpce-menubar-logo"></button>
        <nav className="bpce-menubar-nav" role="navigation" aria-label="Menu principal">
          <ul className="bpce-menubar-left">
            <li>
              <button
                className={
                  dashboardIsActive
                    ? 'bpce-menubar-item bpce-menubar-item-active'
                    : 'bpce-menubar-item bpce-menubar-item-inactive'
                }
                aria-current="page"
                tabIndex="0"
                onClick={() => handleChangeTab(DASHBOARD)}
              >
                Vos initiations de paiement
              </button>
            </li>
            {shouldShowMenu() && (
              <li>
                <button
                  className={
                    paymentLinkIsActive
                      ? 'bpce-menubar-item bpce-menubar-item-active'
                      : 'bpce-menubar-item bpce-menubar-item-inactive'
                  }
                  aria-current="page"
                  tabIndex="0"
                  onClick={() => handleChangeTab(PAYMENT_LINK)}
                >
                  Vos liens de paiement
                </button>
              </li>
            )}
            {shouldShowAspspTabInMenu() && (
              <li>
                <button
                  className={
                    activatedBanksIsActive
                      ? 'bpce-menubar-item bpce-menubar-item-active'
                      : 'bpce-menubar-item bpce-menubar-item-inactive'
                  }
                  aria-current="page"
                  tabIndex="0"
                  onClick={() => handleChangeTab(ACTIVATED_BANKS)}
                >
                  ASPSP accessibles
                </button>
              </li>
            )}
            {shouldShowMenu() && (
              <li>
                <button
                  className={
                    settingsIsActive
                      ? 'bpce-menubar-item bpce-menubar-item-active'
                      : 'bpce-menubar-item bpce-menubar-item-inactive'
                  }
                  aria-current="page"
                  tabIndex="0"
                  onClick={() => handleChangeTab(SETTINGS)}
                >
                  Paramètres
                </button>
              </li>
            )}
          </ul>
          <ul className="bpce-menubar-right" aria-label="Menu secondaire">
            <li>
              <button onClick={() => handleLogout()} className="bpce-menubar-item" tabIndex="0">
                <span
                  className="bpce-menubar-item-icon bpce-icon-font log-out"
                  aria-hidden="true"
                ></span>
                <span className="bpce-menubar-item-label">Déconnexion</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div className="bpce-hero">
        <div className="bpce-hero-title hero-margin">{props.title}</div>
      </div>
    </header>
  );
}
