import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import BankCard from '../../atoms/BankCard/BankCard';
import './BankList.css';
const BankList = (props) => {
  return (
    <ul className={`bank-list ${props.styleName}`}>
      {props.items.length === 0
        ? Array.from(new Array(16)).map((bank, index) => (
            <div key={index} className="loading-table">
              <Skeleton variant="rectangular" width={280} height={80} />
            </div>
          ))
        : props.items.map((bank, index) => (
            <BankCard
              styleName={
                props.selectedId === bank.id
                  ? `bpce-card-bankCard-toogled ${props.styleName}`
                  : props.styleName
              }
              key={bank.id}
              id={bank.id}
              name={bank.name}
              logo={bank.logo}
              index={index}
              setSelectedBank={props.setSelectedBank}
            />
          ))}
    </ul>
  );
};

export default BankList;
