import classes from './ModalOverlay.module.css';
import Card from '../../atoms/Card/Card.js';
import Button from '../../atoms/Button/Button.js';

const ModalOverlay = (props) => {
  return (
    <Card className={classes.modal}>
      <header className={classes.header}>
        <h2>{props.title}</h2>
      </header>
      <div className={classes.content}>
        <p>{props.message}</p>
      </div>
      <footer className={classes.actions}>
        <Button onClick={props.onConfirm}>Confirm</Button>
      </footer>
    </Card>
  );
};

export default ModalOverlay;
