import React from 'react';
import '../../../assets/scss/search-field/src/index.scss';

export default function SearchField(props) {
  function eraseInput() {
    props.setBankName('');
  }

  return (
    <div className="bpce-search-field bpce-search-field-with-button">
      <input
        className="bpce-search-field-input"
        type="text"
        title="Recherche"
        value={props.bankName}
        placeholder="Rechercher votre banque"
        onChange={(e) => props.setBankName(e.target.value)}
      />
      <button className="bpce-search-field-erase" onClick={eraseInput}>
        <span
          className="bpce-icon-font erase"
          role="img"
          aria-label="Vider le champ"
          onClick={eraseInput}
        ></span>
      </button>
      <button className="bpce-search-field-find">
        <span className="bpce-icon-font find" role="img" aria-label="Valider la recherche"></span>
      </button>
    </div>
  );
}
