import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getAuthenticationGaap } from '../../api/authenticationService';
import { useAuth } from '../../context/AuthContext';
import { DASHBOARD_PAGE } from '../../routes/ConstantsRoutes';
const GaapCallbackLoginPage = () => {
  const { storeGaapInfo, loginHandlerGAAP } = useAuth();
  const navigate = useNavigate();

  useQuery(['authGaap'], async () => await getAuthenticationGaap(), {
    onSuccess: (data) => {
      loginHandlerGAAP();
      storeGaapInfo(data);
      navigate(DASHBOARD_PAGE);
    },
    refetchOnWindowFocus: false,
    enabled: true
  });

  return <React.Fragment />;
};

export default GaapCallbackLoginPage;
