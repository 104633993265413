import React from 'react';

import Card from '../Card/Card';
import classes from './Home.module.css';

const Home = (props) => {
  return (
    <Card className={classes.home}>
      {props.firstName === null ||
      props.firstName === undefined ||
      props.lastName === null ||
      props.lastName === undefined ? (
        <h1>Welcome to Extension hub pisp!</h1>
      ) : (
        <h1>{`Welcome back ${props.firstName} ${props.lastName} !`}</h1>
      )}
    </Card>
  );
};

export default Home;
