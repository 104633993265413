import React, { useRef, useState } from 'react';
import '../../../assets/scss/chip/src/index.scss';
import '../../../assets/scss/search-field/src/index.scss';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

export default function MontantFilter({
  label,
  setFilter,
  name,
  isFilterOpen,
  setIsFilterOpen,
  width = 230
}) {
  const maxibulle = useRef();
  const buttonRef = useRef();
  const currentFilter = useRef();
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();

  function openMaxibulle() {
    buttonRef.current.classList.toggle('bpce-chip-active');
    buttonRef.current.setAttribute('aria-expanded', 'true');
    maxibulle.current.setAttribute('data-show', '');
    setIsFilterOpen(true);
  }

  function closeMaxibulle() {
    if (buttonRef.current.classList.contains('bpce-chip-active')) {
      buttonRef.current.classList.remove('bpce-chip-active');
      buttonRef.current.setAttribute('aria-expanded', 'false');
      maxibulle.current.removeAttribute('data-show');
      setIsFilterOpen(false);
    }
  }

  useOnClickOutside(maxibulle, buttonRef, () => {
    closeMaxibulle();
  });

  const updateFilter = (inputName, value) => {
    let array = [];
    if (inputName === 'min') {
      setMinValue(value);
    } else {
      setMaxValue(value);
    }
    array.push(inputName === 'min' ? value : minValue);
    array.push(inputName === 'max' ? value : maxValue);

    const even = (element) => element !== undefined && element.trim() !== '';
    const hasFilter = array.some(even);
    if (hasFilter && !buttonRef.current.classList.contains('bpce-chip-selected')) {
      buttonRef.current.classList.toggle('bpce-chip-selected');
    } else if (!hasFilter) {
      buttonRef.current.classList.remove('bpce-chip-selected');
    }

    setFilter(name, array);
  };

  return (
    <div ref={currentFilter}>
      <button
        ref={buttonRef}
        id="chipActionPanel"
        className="bpce-chip"
        aria-describedby="maxibulle-1"
        aria-expanded="false"
        onClick={() => {
          if (buttonRef.current.classList.contains('bpce-chip-active')) {
            closeMaxibulle();
          } else if (isFilterOpen === false) {
            openMaxibulle();
          }
        }}
      >
        <span className="bpce-chip-label">{label}</span>
      </button>
      <div
        id="maxibulle-1"
        className="bpce-maxibulle"
        role="tooltip"
        placement="bottom-end"
        ref={maxibulle}
        style={{ width: `${width + 150}px`, display: 'flex' }}
      >
        <div>
          <label htmlFor="input-1" className="bpce-input">
            <div className="bpce-input-container">
              <input
                id="input-1"
                step="any"
                className="bpce-input-field w100"
                pattern="[0-9]*"
                type="number"
                placeholder="Min"
                required
                min={0}
                onChange={(e) => updateFilter('min', e.target.value)}
              />
              <span className="bpce-input-label">Min</span>
            </div>
          </label>
        </div>
        <label
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: 10
          }}
        >
          to
        </label>
        <div>
          <label htmlFor="input-2" className="bpce-input">
            <div className="bpce-input-container">
              <input
                id="input-2"
                className="bpce-input-field w100"
                pattern="[0-9]*"
                type="number"
                step="any"
                required={true}
                min={0}
                placeholder="Max"
                onChange={(e) => updateFilter('max', e.target.value)}
              />
              <span className="bpce-input-label">Max</span>
            </div>
          </label>
        </div>
        <span className="bpce-maxibulle-arrow" id="arrow"></span>
      </div>
    </div>
  );
}
