import React, { useRef, useState } from 'react';
import '../../../assets/scss/checkbox/src/index.scss';
import '../../../assets/scss/chip/src/index.scss';
import '../../../assets/scss/search-field/src/index.scss';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

export default function PaymentTypeFilter({
  label,
  setFilter,
  name,
  isFilterOpen,
  setIsFilterOpen,
  isFetching
}) {
  const maxibulle = useRef();
  const buttonRef = useRef();
  const currentFilter = useRef();
  const IP = 'IP';
  const SCT = 'SCT';
  const [chkFilters, setChkFilters] = useState(['NONE', IP, SCT]);

  function openMaxibulle() {
    buttonRef.current.classList.toggle('bpce-chip-active');
    buttonRef.current.setAttribute('aria-expanded', 'true');
    maxibulle.current.setAttribute('data-show', '');
    setIsFilterOpen(true);
  }

  function closeMaxibulle() {
    if (buttonRef.current.classList.contains('bpce-chip-active')) {
      buttonRef.current.classList.remove('bpce-chip-active');
      buttonRef.current.setAttribute('aria-expanded', 'false');
      maxibulle.current.removeAttribute('data-show');
      setIsFilterOpen(false);
    }
  }

  useOnClickOutside(maxibulle, buttonRef, () => {
    closeMaxibulle();
  });

  const onChangeStatus = (e) => {
    const value = e.target.name;
    const index = chkFilters.indexOf(value);

    let filter = chkFilters;
    if (!e.target.checked) {
      filter.splice(index, 1);
      if (!buttonRef.current.classList.contains('bpce-chip-selected')) {
        buttonRef.current.classList.toggle('bpce-chip-selected');
      } else {
        buttonRef.current.classList.remove('bpce-chip-selected');
      }
    } else {
      filter.push(value);
      buttonRef.current.classList.remove('bpce-chip-selected');
    }
    setFilter(name, filter);
    setChkFilters(filter);
  };

  return (
    <div ref={currentFilter}>
      <button
        ref={buttonRef}
        id="chipActionPanel"
        className="bpce-chip"
        aria-describedby="maxibulle-1"
        aria-expanded="false"
        onClick={() => {
          if (buttonRef.current.classList.contains('bpce-chip-active')) {
            closeMaxibulle();
          } else if (isFilterOpen === false) {
            openMaxibulle();
          }
        }}
      >
        <span className="bpce-chip-label">{label}</span>
      </button>
      <div
        id="maxibulle-1"
        className="bpce-maxibulle"
        role="tooltip"
        placement="bottom-end"
        ref={maxibulle}
        style={{ width: '100px' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="bpce-checkbox">
            <input
              className="bpce-checkbox-input"
              type="checkbox"
              id={IP}
              name={IP}
              disabled={isFetching}
              checked={chkFilters.includes(IP) ? 'checked' : ''}
              onChange={onChangeStatus}
            />
            <label className="bpce-checkbox-label" htmlFor={IP}>
              <div className="bpce-checkbox-square" role="checkbox">
                <svg className="bpce-checkbox-icon" aria-hidden="true" viewBox="0 0 32 32">
                  <path fill="none" d="M5,15 13,23 27,9" />
                </svg>
              </div>
              {IP}
            </label>
          </div>

          <div className="bpce-checkbox">
            <input
              className="bpce-checkbox-input"
              type="checkbox"
              id={SCT}
              name={SCT}
              disabled={isFetching}
              checked={chkFilters.includes(SCT) ? 'checked' : ''}
              onChange={onChangeStatus}
            />
            <label className="bpce-checkbox-label" htmlFor={SCT}>
              <div className="bpce-checkbox-square" role="checkbox">
                <svg className="bpce-checkbox-icon" aria-hidden="true" viewBox="0 0 32 32">
                  <path fill="none" d="M5,15 13,23 27,9" />
                </svg>
              </div>
              {SCT}
            </label>
          </div>
        </div>

        <span className="bpce-maxibulle-arrow" id="arrow"></span>
      </div>
    </div>
  );
}
