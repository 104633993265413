import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

import { getsAllPaymentInitiations } from '../../api/dashBoardService';
import DatePickerRange from '../../components/molecules/Datepicker/DatePickerRange';
import Filters from '../../components/molecules/Filters/FiltersInitiation';
import Header from '../../components/molecules/HeaderDashBoard/HeaderDashBoard';
import Panel from '../../components/molecules/Panel/Panel';
import SuiviContent from '../../components/organism/SuiviContent/SuiviContent';

import { useMutation } from 'react-query';
import { getLogout } from '../../api/authenticationService';
import '../../assets/scss/chip/src/index.scss';
import '../../assets/scss/datatable/src/index.scss';
import '../../assets/scss/header/src/index.scss';
import '../../assets/scss/menubar/src/index.scss';
import '../../assets/scss/panel/src/index.scss';
import '../../assets/scss/search-field/src/index.scss';
import '../../assets/scss/tile/src/index.scss';
import { INITIATION } from '../../components/molecules/Filters/ConstantsFilter';
import ErrorModal from '../../components/organism/ErrorModal/ErrorModal';
import { config } from '../../config';
import { useAuth } from '../../context/AuthContext';
import './DashboardPage.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function DashboardPage() {
  const { logoutHandler } = useAuth();
  const Logout = () => {
    mutate();
    logoutHandler();
  };

  const { mutate } = useMutation(['authGaap'], async () => await getLogout(), {
    onSuccess: () => {},
    refetchOnWindowFocus: false,
    enabled: false
  });

  return (
    <QueryClientProvider client={queryClient}>
      <InitiationListPage logoutHandler={() => Logout()} />
    </QueryClientProvider>
  );
}

function InitiationListPage(props) {
  const [pageable, setPageable] = useState({
    pageNumber: 0,
    pageSize: config.defaultPageSize,
    sort: {
      id: 'creationDateTime',
      desc: true
    }
  });
  const [filter, setFilter] = useState({});
  const [startDate, setStartDate] = useState(
    new Date(
      moment()
        .subtract(`${config.limitDaysDashboardFilter - 1}`, 'days')
        .format('MM-DD-YYYY')
    )
  );
  const [endDate, setEndDate] = useState(new Date(moment().format('MM-DD-YYYY')));

  const [loading, setLoading] = useState(false);

  const { isLoading, error, data, isFetching, refetch } = useQuery(
    ['repoData'],
    async () =>
      await getsAllPaymentInitiations(
        {
          requestedDateStart: moment(startDate).format('DD-MM-YYYY'),
          requestedDateEnd: moment(endDate).format('DD-MM-YYYY'),
          ...filter
        },
        pageable
      )
  );

  if (data && data?.pageable) data.pageable.sort = pageable.sort;
  useEffect(() => {
    if (data && data?.pageable) data.pageable.sort = pageable.sort;
  }, [data]);
  useEffect(async () => {
    await refetch();
    setLoading(false);
  }, [pageable]);

  if (isLoading || loading)
    return (
      <div className="loading-screen">
        <div className="loading-filter" />
        <Header title="Tableau de suivi d'initiation de paiement" />
        <div className="body-margin">
          <Panel />
          <DatePickerRange />
          <Filters />
          <div className="table-loader">
            <svg
              className="bpce-loader-svg"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <circle cx="50" cy="50" r="45" />
            </svg>
          </div>
        </div>
      </div>
    );

  if (error?.response?.data) {
    return (
      <ErrorModal
        title="Attention"
        message={error.response.data}
        onConfirm={() => {
          location.reload();
        }}
      />
    );
  } else if (error) return 'An error has occurred: ' + error.message;

  function multiSelectCheckboxFilter(rows, columnIds, filterValue) {
    return filterValue.length === 0
      ? rows
      : rows.filter((row) => filterValue.includes(String(row.original[columnIds])));
  }

  function numberRangeFilter(rows, columnIds, filterValue) {
    const filterMin =
      filterValue.length > 0 && filterValue[0] && filterValue[0] !== ''
        ? Number(filterValue[0])
        : undefined;
    const filterMax =
      filterValue.length > 1 && filterValue[1] && filterValue[1] !== ''
        ? Number(filterValue[1])
        : undefined;
    return filterMin || filterMax
      ? rows.filter((row) => {
          const value = row.original['amount'];
          if (filterMin && filterMax) {
            return value >= filterMin && value <= filterMax;
          } else if (filterMin) {
            return value >= filterMin;
          } else {
            return value <= filterMax;
          }
        })
      : rows;
  }

  function compareDate(rowA, rowB) {
    let dateA = moment(rowA.values.creationDateTime, 'DD-MM-YYYY HH:mm:ss').format(
      'YYYYMMDDHHmmss'
    );

    let dateB = moment(rowB.values.creationDateTime, 'DD-MM-YYYY HH:mm:ss').format(
      'YYYYMMDDHHmmss'
    );

    if (dateA > dateB) return 1;
    if (dateA < dateB) return -1;
    return 0;
  }

  const columns = [
    {
      Header: <span>Statut</span>,
      accessor: 'transactionStatus',
      Cell: (props) => {
        let colorStatus = 'orange';
        if (props.value === 'EXÉCUTÉ') {
          colorStatus = 'green';
        } else if (props.value === 'REJETÉ') {
          colorStatus = 'red';
        }
        return <span className={`status-icon status-icon-${colorStatus}`}>{props.value}</span>;
      },
      disableFilters: true,
      filter: multiSelectCheckboxFilter,
      width: '10%'
    },
    {
      Header: <span>Type de paiement</span>,
      accessor: 'type',
      disableFilters: true,
      filter: multiSelectCheckboxFilter,
      width: '12%'
    },
    {
      Header: <span>Date de création</span>,
      accessor: 'creationDateTime',
      disableFilters: true,
      sortType: compareDate,
      width: '15%'
    },
    {
      Header: <span>Resource Id</span>,
      accessor: 'resourceId',
      disableFilters: true,
      width: '25%'
    },
    {
      Header: <span>End to End ID</span>,
      accessor: 'endToEnd',
      disableFilters: true,
      width: '18%'
    },
    {
      Header: <span>Remittance</span>,
      accessor: 'remittanceInformation',
      disableFilters: true,
      width: '15%'
    },
    {
      Header: <span>Dèpartement</span>,
      accessor: 'department',
      disableFilters: true,
      width: '15%'
    },
    {
      Header: <span>BIC banque du client</span>,
      accessor: 'bicDebtor',
      disableFilters: true,
      width: '15%'
    },
    {
      Header: <span>Montant</span>,
      accessor: 'formatAmount',
      Cell: (props) => {
        return <span>{props.value} €</span>;
      },
      disableFilters: true,
      filter: numberRangeFilter,
      width: '12%'
    }
  ];

  return (
    <div>
      <Header
        onLogout={props.logoutHandler}
        activePage="dashboard"
        title="Tableau de suivi d'initiation de paiement"
      />
      <div className="body-margin" style={{ cursor: 'pointer' }}>
        <SuiviContent
          data={data}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          refetch={async (page, filter) => {
            if (page === undefined) {
              setLoading(true);
            }
            if (filter) {
              setFilter(filter);
              page.pageNumber = 0;
            }
            setPageable(page);
          }}
          isFetching={isFetching}
          columnsDefinition={columns}
          filterConstant={INITIATION}
          resourceIdProperty={'resourceId'}
        />
      </div>
    </div>
  );
}
