import React from 'react';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import { DASHBOARD_PAGE, PAYMENT_LINK_GENERATOR_PAGE } from '../../../routes/ConstantsRoutes';
import classes from './Navigation.module.css';
const Navigation = (props) => {
  const onLogin = () => {
    props.Login();
  };

  const onLogout = () => {
    props.Logout();
  };

  return (
    <nav className={classes.nav}>
      <ul>
        {props.isAuthenticated && (
          <li>
            <Link to={DASHBOARD_PAGE}>Dashboard</Link>
          </li>
        )}
        {JSON.parse(config.showPaymentMock) && props.isAuthenticated && props.isAdmin && (
          <li>
            <Link to={PAYMENT_LINK_GENERATOR_PAGE}>Payment Mock</Link>
          </li>
        )}
        {props.isAuthenticated ? (
          <li>
            <button onClick={() => onLogout()}>Logout</button>
          </li>
        ) : (
          <li>
            <button onClick={() => onLogin()}>Login</button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navigation;
