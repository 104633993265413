import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import moment from 'moment';

import { useQuery } from 'react-query';
import { getPaymentInitiation } from '../../../api/dashBoardService';
import '../../../assets/scss/loader/src/index.scss';
import '../../../assets/scss/shimmering/src/index.scss';
import './Panel.scss';
import { SuiviContent } from './PanelSuiviContent';

const defaultDetails = {
  stepStatusArray: ['', '', '', '']
};

const Panel = ({ panelOpen, setPanelOpen, resourceId }, ref) => {
  const panel = useRef();
  const [details, setDetails] = useState(defaultDetails);

  const { isLoading, isFetching } = useQuery(
    ['initiationDetail', resourceId],
    async () => await getPaymentInitiation(resourceId),
    {
      onSuccess: (data) => {
        setDetails(data);
      },
      retry: (failureCount, error) => {
        if (error?.response?.status === 500 && failureCount < 1) {
          return true;
        }
        return false;
      },
      enabled: resourceId !== null
    }
  );

  useImperativeHandle(ref, () => ({
    togglePanel,
    closePanel
  }));

  function togglePanel() {
    setPanelOpen(!panelOpen);
    panel.current.classList.toggle('bpce-panel-open');
  }

  function closePanel() {
    if (panel.current.classList.contains('bpce-panel-open')) {
      panel.current.classList.remove('bpce-panel-open');
      setPanelOpen(false);
    }
  }

  function renderDepartment(description) {
    return description === undefined || description === null ? '---' : description;
  }

  if (isLoading || isFetching)
    return (
      <>
        <div ref={panel} className="bpce-panel bpce-panel-position-right panel">
          <div className="bpce-panel-container" style={{ top: '145px' }}>
            <div className="bpce-panel-header">
              <div className="bpce-header">
                <div className="bpce-header-left">
                  <button
                    type="button"
                    className="
                bpce-header-close
                bpce-button
                bpce-button-secondary
                bpce-button-icon-only
                bpce-button-no-border
              "
                    aria-label="Bouton de fermeture"
                    onClick={() => {
                      closePanel();
                    }}
                  >
                    <span className="bpce-icon-font close"></span>
                  </button>
                </div>
                <div className="bpce-header-left">
                  <div className="bpce-header-ellipsis-container">
                    <span className="panel-title">
                      <strong>Resource Id:</strong> {resourceId}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="div1"
              style={{
                height: '2px',
                backgroundColor: 'purple'
              }}
            ></div>
            <div className="bpce-panel-body panel-body">
              <h3>Suivi de l'opération : </h3>
              <SuiviContent
                details={details}
                stepStatusArray={defaultDetails.stepStatusArray}
                isLoading={true}
              />
              <h3>Banque du client :</h3>
              <div>
                <div className="inline">
                  <span className="detail-label">Nom banque : </span>
                </div>
                <div className="data-text loading inline" />
              </div>
              <div>
                <span className="detail-label">BIC : </span>
                <div className="data-text loading inline" />
              </div>
              <div>
                <span className="detail-label">IBAN : </span>
                <div className="data-text loading inline" />
              </div>
              <h3>Compte crédité :</h3>
              <div>
                <span className="detail-label">Nom banque : </span>
                <div className="data-text loading inline" />
              </div>
              <div>
                <span className="detail-label">BIC : </span>
                <div className="data-text loading inline" />
              </div>
              <div>
                <span className="detail-label">IBAN : </span>
                <div className="data-text loading inline" />
              </div>
              <h3>Détail :</h3>
              <div>
                <span className="detail-label">Type : </span>
                <div className="data-text loading inline" />
              </div>
              <div>
                <span className="detail-label">Montant : </span>
                <div className="data-text loading inline" />
              </div>
              <div>
                <span className="detail-label">Date de création : </span>
                <div className="data-text loading inline" />
              </div>
              <div>
                <span className="detail-label">Date d'exécution : </span>
                <div className="data-text loading inline" />
              </div>
              <div>
                <span className="detail-label">End to end ID : </span>
                <div className="data-text loading inline" />
              </div>
              <div>
                <span className="detail-label">Remittance : </span>
                <div className="data-text loading inline" />
              </div>
              <div>
                <span className="detail-label">Département : </span>
                <div className="data-text loading inline" />
              </div>
              <br />
            </div>
          </div>
        </div>
      </>
    );

  return (
    <div ref={panel} className="bpce-panel bpce-panel-position-right panel">
      <div className="bpce-panel-container" style={{ top: '145px', overflowY: 'auto' }}>
        <div className="bpce-panel-header">
          <div className="bpce-header">
            <div className="bpce-header-left">
              <button
                type="button"
                className="
                bpce-header-close
                bpce-button
                bpce-button-secondary
                bpce-button-icon-only
                bpce-button-no-border
              "
                aria-label="Bouton de fermeture"
                onClick={() => {
                  closePanel();
                }}
              >
                <span className="bpce-icon-font close"></span>
              </button>
            </div>
            <div className="bpce-header-left">
              <div className="bpce-header-ellipsis-container">
                <span className="panel-title">
                  <strong>Resource Id:</strong> {resourceId}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="div1"
          style={{
            height: '2px',
            backgroundColor: 'purple'
          }}
        ></div>
        <div className="bpce-panel-body panel-body">
          <h3>Suivi de l'opération : </h3>
          <SuiviContent
            details={details}
            stepStatusArray={details.stepStatusArray}
            hubRejectCause={details.hubRejectCause}
            isLoading={false}
          />
          <h3>Banque du client :</h3>
          <div>
            <span className="detail-label">Nom banque : </span>
            <span className="detail-info">
              {details.debtorAgentName === 'UNKNOWN ASPSP'
                ? 'Banque non déterminée'
                : details.debtorAgentName}
            </span>
          </div>
          {details.bicDebtor !== null && (
            <div>
              <span className="detail-label">BIC : </span>
              <span className="detail-info">{details.bicDebtor}</span>
            </div>
          )}
          {details.ibanDebtor !== null && (
            <div>
              <span className="detail-label">IBAN : </span>
              <span className="detail-info">{details.ibanDebtor}</span>
            </div>
          )}
          <h3>Compte crédité :</h3>
          <div>
            <span className="detail-label">Nom banque : </span>
            <span className="detail-info">
              {details.creditorAgentName === 'UNKNOWN ASPSP'
                ? 'Banque non déterminée'
                : details.creditorAgentName}
            </span>
          </div>
          {details.bicCreditor !== '' && (
            <div>
              <span className="detail-label">BIC : </span>
              <span className="detail-info">{details.bicCreditor}</span>
            </div>
          )}
          {details.ibanCreditor !== null && (
            <div>
              <span className="detail-label">IBAN : </span>
              <span className="detail-info">{details.ibanCreditor}</span>
            </div>
          )}
          <h3>Détail :</h3>
          <div>
            <span className="detail-label">Type : </span>
            <span className="detail-info">{details.type}</span>
          </div>
          <div>
            <span className="detail-label">Montant : </span>
            <span className="detail-info">{details.formatAmount} €</span>
          </div>
          <div>
            <span className="detail-label">Date de création : </span>
            <span className="detail-info">
              {moment(details.creationDateTime, 'YYYY-MM-DD HH:mm:ss').format(
                'DD-MM-YYYY HH:mm:ss'
              )}
            </span>
          </div>
          <div>
            <span className="detail-label">Date d'exécution : </span>
            <span className="detail-info">
              {moment(details.requestedExecutionDate, 'YYYY-MM-DD HH:mm:ss').format(
                'DD-MM-YYYY HH:mm:ss'
              )}
            </span>
          </div>
          <div>
            <span className="detail-label">End to end ID : </span>
            <span className="detail-info">{details.endToEnd}</span>
          </div>
          <div>
            <span className="detail-label">Remittance : </span>
            <span className="detail-info">{details.remittanceInformation}</span>
          </div>
          <div>
            <span className="detail-label">Département : </span>
            <span className="detail-info">{renderDepartment(details.department)}</span>
          </div>
          <div>
            <span className="detail-label">Resource Id : </span>
            <span className="detail-info">{details.resourceId}</span>
          </div>
          {details.hubRejectCause && (
            <React.Fragment>
              <h3>Rejection reason : </h3>
              <div>
                <span className="detail-label">
                  {details.hubRejectCause.split(' - ')[1]
                    ? details.hubRejectCause.split(' - ')[1]
                    : 'Erreur'}
                </span>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Panel);
