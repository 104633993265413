/* eslint-disable no-undef */
const dev = {
  level: 'dev',
  proxyUrl: 'http://localhost:8089/',
  contextRoot: '/api/v1/',
  appId: '5864c21d-2079-4b09-b25d-e2a73b9baf69',
  gaapLogin: 'https://localhost:3001/saml/login?disco=true&idp=GAAP_QUA_NXHP7',
  cguPdfUrl: 'http://localhost:8088/hub-pisp-operations/v2/payment-initiation-service-provider/pdf',
  showPaymentMock: true,
  limitDaysDashboardFilter: 10,
  defaultPageSize: 10,
  secretKeyEncrypt: '3Z/45/e3i0mN1NIqjqRA46T+PZ4MvXEuySxPC5fMg2c='
};

const prod = {
  level: 'prod',
  proxyUrl: '%#SERVER_URL#%',
  contextRoot: '%#HUB_EXTENSION_CONTEXT_ROOT#%',
  appId: '%#APP_ID#%',
  gaapLogin: '%#GAAP_FRONT#%',
  cguPdfUrl: '%#CGU_PDF_URL#%',
  showPaymentMock: '%#SHOW_PAYMENT_MOCK#%',
  limitDaysDashboardFilter: '%#LIMIT_DAYS_DASHBOARD_FILTER#%',
  defaultPageSize: 10,
  secretKeyEncrypt: '%#SECRET_KEY_ENCRYPT#%'
};

const config = process.env.NODE_ENV === 'production' ? prod : dev;

module.exports = { config };
