import React from 'react';

const SuiviContent = (props) => {
  const hubRejectCause = props.hubRejectCause;
  const stepStatus = {
    send: props.stepStatusArray[0],
    receive: props.stepStatusArray[1],
    validation: props.stepStatusArray[2],
    execution: props.stepStatusArray[3]
  };

  if (props.isLoading)
    return (
      <ul className="suivi-content">
        <li>
          <span className={`suivi-logo suivi-logo bpce-icon-font page`} />
          <span className={`logo-path-0 logo-path-0`} />
          <div className="suivi-step">
            <div className="suivi-step-label">Envoi à la banque client</div>
            <div className="suivi-step-info">
              <div className="data-text  " />
            </div>
          </div>
        </li>
        <li>
          <span className={`suivi-logo suivi-logo bpce-icon-font edit`} />
          <span className={`logo-path-1 logo-path-1`} />
          <div className="suivi-step">
            <div className="suivi-step-label">Réception par la banque du client</div>
            <div className="suivi-step-info">
              <div className="data-text  " />
            </div>
          </div>
        </li>
        <li>
          <span className={`suivi-logo suivi-logo bpce-icon-font thumbs-up`} />
          <span className={`logo-path-2 logo-path-2`} />
          <div className="suivi-step">
            <div className="suivi-step-label">Validation par le client</div>
            <div className="suivi-step-info">
              <div className="data-text  " />
            </div>
          </div>
        </li>
        <li>
          <span className={`suivi-logo suivi-logo bpce-icon-font euro`} />
          <div className="suivi-step">
            <div className="suivi-step-label">Exécution du paiement</div>
            <div className="suivi-step-info">
              <div className="data-text " />
            </div>
          </div>
        </li>
      </ul>
    );

  return (
    <ul className="suivi-content">
      <li>
        {stepStatus.send === 'ok' ? (
          <React.Fragment>
            <span
              className={`suivi-logo suivi-logo-${stepStatus.send} bpce-icon-font page`}
              /*wait icon : more or media-duration*/
            />
            <span className={`logo-path-0 logo-path-0-${stepStatus.send}`} />
          </React.Fragment>
        ) : stepStatus.send === 'wait' ? (
          <span
            className={`suivi-logo suivi-logo-${stepStatus.send} bpce-icon-font media-duration`}
          />
        ) : (
          <span className={`suivi-logo suivi-logo-${stepStatus.send} bpce-icon-font thumbs-down`} />
        )}
        <div className="suivi-step">
          <div className="suivi-step-label">Envoi à la banque client</div>
          <div className="suivi-step-info">
            {stepStatus.send === 'ok' ? (
              <span>Envoyé</span>
            ) : stepStatus.send === 'ko' ? (
              <span> {hubRejectCause ? hubRejectCause.split(' - ')[0] : 'Erreur'} </span>
            ) : (
              stepStatus.send === 'wait' && <span>En attente</span>
            )}
          </div>
        </div>
      </li>
      {stepStatus.send === 'ok' && (
        <li>
          {stepStatus.receive === 'ok' ? (
            <React.Fragment>
              <span className={`suivi-logo suivi-logo-${stepStatus.receive} bpce-icon-font edit`} />
              <span className={`logo-path-1 logo-path-1-${stepStatus.receive}`} />
            </React.Fragment>
          ) : stepStatus.receive === 'wait' ? (
            <span
              className={`suivi-logo suivi-logo-${stepStatus.receive} bpce-icon-font media-duration`}
            />
          ) : (
            <span
              className={`suivi-logo suivi-logo-${stepStatus.receive} bpce-icon-font thumbs-down`}
            />
          )}
          <div className="suivi-step">
            <div className="suivi-step-label">Réception par la banque du client</div>
            <div className="suivi-step-info">
              {stepStatus.receive === 'ok' ? (
                <span>Reçu</span>
              ) : stepStatus.receive === 'ko' ? (
                <span> {hubRejectCause ? hubRejectCause.split(' - ')[0] : 'Erreur'} </span>
              ) : (
                stepStatus.receive === 'wait' && <span>En attente</span>
              )}
            </div>
          </div>
        </li>
      )}
      {stepStatus.receive === 'ok' && (
        <li>
          {stepStatus.validation === 'ok' ? (
            <React.Fragment>
              <span
                className={`suivi-logo suivi-logo-${stepStatus.validation} bpce-icon-font thumbs-up`}
              />
              <span className={`logo-path-2 logo-path-2-${stepStatus.validation}`} />
            </React.Fragment>
          ) : stepStatus.validation === 'wait' ? (
            <span
              className={`suivi-logo suivi-logo-${stepStatus.validation} bpce-icon-font media-duration`}
            />
          ) : (
            <span
              className={`suivi-logo suivi-logo-${stepStatus.validation} bpce-icon-font thumbs-down`}
            />
          )}
          <div className="suivi-step">
            <div className="suivi-step-label">Validation par le client</div>
            <div className="suivi-step-info">
              {stepStatus.validation === 'ok' ? (
                <span>Validé</span>
              ) : stepStatus.validation === 'ko' ? (
                <span>{hubRejectCause ? hubRejectCause.split(' - ')[0] : 'Erreur'}</span>
              ) : (
                stepStatus.validation === 'wait' && <span>En attente</span>
              )}
            </div>
          </div>
        </li>
      )}
      {stepStatus.validation === 'ok' && (
        <li>
          {stepStatus.execution === 'ok' ? (
            <span className={`suivi-logo suivi-logo-${stepStatus.execution} bpce-icon-font euro`} />
          ) : stepStatus.execution === 'wait' ? (
            <span
              className={`suivi-logo suivi-logo-${stepStatus.execution} bpce-icon-font media-duration`}
            />
          ) : (
            <span
              className={`suivi-logo suivi-logo-${stepStatus.execution} bpce-icon-font thumbs-down`}
            />
          )}
          <div className="suivi-step">
            <div className="suivi-step-label">Exécution du paiement</div>
            <div className="suivi-step-info">
              {stepStatus.execution === 'ok' ? (
                <span>Exécuté</span>
              ) : stepStatus.execution === 'ko' ? (
                <span>{hubRejectCause ? hubRejectCause.split(' - ')[0] : 'Erreur'}</span>
              ) : (
                stepStatus.execution === 'wait' && <span>En attente</span>
              )}
            </div>
          </div>
        </li>
      )}
    </ul>
  );
};

export { SuiviContent };
