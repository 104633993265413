import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getAuthenticationGaap, getAuthenticationJwt } from '../../api/authenticationService';
import Login from '../../components/organism/Login/Login';
import { config } from '../../config';
import { useAuth } from '../../context/AuthContext';
import { DASHBOARD_PAGE } from '../../routes/ConstantsRoutes';

const LoginPage = () => {
  const { storeGaapInfo, storeJWTInfo, loginHandlerJWT, loginHandlerGAAP } = useAuth();
  const [errorconnection, setErrrorConnection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const OnLoginJwt = async (identification, password) => {
    loginJwt({ identification, password });
    setIsLoading(true);
  };

  const OnLoginGaap = async () => {
    loginGaap();
  };

  const { mutate: loginJwt } = useMutation(
    ['authJWT'],
    async ({ identification, password }) => await getAuthenticationJwt(identification, password),
    {
      onSuccess: (data) => {
        storeJWTInfo(data);
        loginHandlerJWT();
        navigate(DASHBOARD_PAGE);
      },
      onError: () => {
        setErrrorConnection(true);
        setIsLoading(false);
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  const { mutate: loginGaap } = useMutation(
    ['authGaap'],
    async () => await getAuthenticationGaap(),
    {
      onSuccess: (data) => {
        if (!data.userId) {
          window.location.href = `${config.gaapLogin}`;
        } else {
          storeGaapInfo(data);
          loginHandlerGAAP();
          navigate(DASHBOARD_PAGE);
        }
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  return (
    <React.Fragment>
      <main>
        <Login
          onLoginJwt={OnLoginJwt}
          isLoading={isLoading}
          onLoginGaap={OnLoginGaap}
          error={errorconnection}
        />
      </main>
    </React.Fragment>
  );
};

export default LoginPage;
