import React from 'react';
import { useQuery } from 'react-query';
import { getUser } from '../../api/userService';
import Home from '../../components/atoms/Home/Home';
import MainHeader from '../../components/molecules/MainHeader/MainHeader';
import { useAuth } from '../../context/AuthContext';
import './MainPage.scss';
const MainPage = () => {
  const { tokenInfo, userInfo, storeUserInfo } = useAuth();

  useQuery(['user'], async () => await getUser(tokenInfo?.userId), {
    onSuccess: (data) => {
      storeUserInfo(data);
    },
    onError: () => {},
    refetchOnWindowFocus: false,
    enabled: tokenInfo?.userId !== null
  });

  return (
    <React.Fragment>
      <MainHeader />
      <main>
        <Home firstName={userInfo?.firstName} lastName={userInfo?.lastName} />
      </main>
    </React.Fragment>
  );
};

export default MainPage;
