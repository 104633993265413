import React from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '../../atoms/Backdrop/Backdrop.js';
import ModalOverlay from '../../molecules/ModalOverlay/ModalOverlay.js';

const ErrorModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop onConfirm={props.onConfirm} />,
        document.getElementById('backdrop-root')
      )}

      {ReactDOM.createPortal(
        <ModalOverlay title={props.title} message={props.message} onConfirm={props.onConfirm} />,
        document.getElementById('overlay-root')
      )}
    </React.Fragment>
  );
};

export default ErrorModal;
