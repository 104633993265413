import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { postPaymentInitiation, retrievePisp } from '../../../api/consentService';
import '../../../assets/scss/buttons/src/index.scss';
import '../../../assets/scss/modal/src/index.scss';
import '../../../assets/scss/toaster/src/index.scss';
import { formatNumberFrenchConvention, formatString } from '../../../util/Formater';
import { showErrorToast } from '../../../util/ToastUtil';
import ModalFooter from '../../molecules/ModalFooter/ModalFooter';
import ModalHeader from '../../molecules/ModalHeader/ModalHeader';
import PanelIban from '../../molecules/PanelIban/PanelIban';
import Toaster from '../../molecules/Toaster/Toaster';
import PanelChoose from '../PanelChoose/PanelChoose';
import PanelChooseHeadCompany from '../PanelChooseHeadCompany/PanelChooseHeadCompany';
import './Modal.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
}

export default function Modal({ banks, client, paymentDetails, resourceId, isLoadingClient }) {
  const HEAD_COMPANY_TAB = 0;
  const DESK_TAB = 1;
  const IBAN_TAB = 2;
  const [bankName, setBankName] = useState('');
  const [tabValue, setTabValue] = useState(HEAD_COMPANY_TAB);
  const [selectedBankId, setSelectedBankId] = useState();
  const [selectedDeskId, setSelectedDeskId] = useState();
  const [selectedBankIndex, setSelectedBankIndex] = useState();
  const [selectedDeskIndex, setSelectedDeskIndex] = useState();
  const [validationEnabled, setValidationEnabled] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [iban, setIban] = useState('');
  const appId = client?.appId;
  //TODO to modify with callback
  const submitPayment = async () => {
    if (
      paymentDetails.localInstrument == 'INST' &&
      banks[selectedBankIndex].aspsps[selectedDeskIndex].paymentAmountLimit < paymentDetails.amount
    ) {
      showErrorToast(
        () =>
          `${formatNumberFrenchConvention(paymentDetails.amount)} ${
            paymentDetails.currency
          } est supérieur au montant maximal de ${formatString(
            banks[selectedBankIndex].aspsps[selectedDeskIndex].name
          )} pour un virement instantané.`
      );
    } else {
      setLoadingPage(true);
      console.log('Submit payment');
      let body = '';

      if (
        tabValue === HEAD_COMPANY_TAB &&
        selectedBankId !== undefined &&
        banks[selectedBankIndex].aspsps.length === 1
      ) {
        console.log('aspspId=' + selectedDeskId);

        body = {
          aspspId: selectedDeskId
        };
      } else if (tabValue === DESK_TAB && selectedDeskId !== undefined) {
        console.log('aspspId=' + selectedDeskId);

        body = {
          aspspId: selectedDeskId
        };
      } else {
        console.log('aspspId=' + selectedDeskId);
        console.log('iban=' + iban);

        body = {
          aspspId: selectedDeskId,
          iban: iban
        };
      }
      const { data } = await postPaymentInitiation(resourceId, body);

      if (data) {
        window.location.replace(data.url);
      }
    }
  };

  const {
    data: pisp,
    isLoading,
    isFetching
  } = useQuery(['getPisp', appId], async () => await retrievePisp(appId), {
    onSuccess: (data) => {
      return data;
    },
    enabled: true,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    setBankName('');
  }, [tabValue]);

  useEffect(() => {
    if (selectedBankId !== undefined) {
      setSelectedBankIndex(getBankIndex(banks, selectedBankId));
    }
  }, [selectedBankId, banks]);

  useEffect(() => {
    if (selectedBankIndex !== undefined) {
      if (banks[selectedBankIndex].aspsps.length > 1) {
        setTabValue(DESK_TAB);
      } else {
        setSelectedDeskId(banks[selectedBankIndex].aspsps[0].id);
      }
    }
  }, [selectedBankIndex, banks]);

  useEffect(() => {
    if (selectedDeskId !== undefined) {
      setSelectedDeskIndex(getBankIndex(banks[selectedBankIndex].aspsps, selectedDeskId));
    }
  }, [selectedDeskId, selectedBankIndex, banks]);

  useEffect(() => {
    setValidationEnabled(false);
    if (selectedDeskIndex !== undefined) {
      const approachArray = banks[selectedBankIndex].aspsps[selectedDeskIndex]?.approach;
      if (approachArray?.length === 1 && approachArray[0] === 'IBAN') {
        setTabValue(IBAN_TAB);
      } else {
        setValidationEnabled(true);
      }
    }
  }, [selectedDeskIndex, selectedBankIndex, banks]);

  useEffect(() => {
    if (tabValue === HEAD_COMPANY_TAB) {
      setSelectedBankId(undefined);
      setSelectedDeskId(undefined);
      setSelectedBankIndex(undefined);
      setSelectedDeskIndex(undefined);
    } else if (tabValue === DESK_TAB) {
      setSelectedDeskId(undefined);
      setSelectedDeskIndex(undefined);
    }
  }, [tabValue]);

  function goBack() {
    let bankIndex = getBankIndex(banks, selectedBankId);
    if (banks[bankIndex].aspsps.length === 1) setTabValue(HEAD_COMPANY_TAB);
    else setTabValue(tabValue - 1);
  }

  const filterText = (arr, requete) => {
    if (arr === undefined) return [];
    return arr.filter(
      (el) => el.name && el.name.toLowerCase().indexOf(requete.toLowerCase()) !== -1
    );
  };

  const getBankIndex = (arr, requete) => {
    return arr.map((object) => object.id).indexOf(requete);
  };

  if (loadingPage) {
    <React.Fragment>
      <Toaster client={client} url={paymentDetails?.unsuccessfulReportUrl} />
    </React.Fragment>;
  } else {
    return (
      <React.Fragment>
        <div
          id="modalInFullPageModeWithFewContent"
          className="bpce-modal bpce-modal-fullscreen bpce-modal-footer-is-sticky bpce-modal-with-scroll modal"
          role="dialog"
          aria-modal="true"
          aria-hidden="true"
          aria-describedby="modalInFullPageModeWithFewContentDescription"
          tabIndex="-1"
        >
          <span className="bpce-modal-backdrop"></span>
          <div className="bpce-modal-animate-container">
            <div className="bpce-modal-container">
              <ModalHeader
                clientName={client?.name}
                clientImage={client?.image}
                onCloseRedirect={paymentDetails?.unsuccessfulReportUrl}
                tabValue={tabValue}
                goBack={goBack}
                pispLogo={pisp?.logo}
                isLoadingClient={isLoadingClient}
                isLoadingPisp={isLoading || isFetching}
              />
              <div className="bpce-modal-body">
                {/* TAB 1 */}
                <TabPanel value={tabValue} index={HEAD_COMPANY_TAB}>
                  <PanelChooseHeadCompany
                    banks={filterText(banks, bankName)}
                    setSelectedBank={setSelectedBankId}
                    setBankName={setBankName}
                    bankName={bankName}
                    selectedId={selectedBankId}
                  />
                </TabPanel>
                {/* TAB 2 */}
                <TabPanel value={tabValue} index={DESK_TAB}>
                  {banks && (
                    <PanelChoose
                      banks={filterText(banks[selectedBankIndex]?.aspsps, bankName)}
                      setSelectedBank={setSelectedDeskId}
                      setBankName={setBankName}
                      bankName={bankName}
                      selectedId={selectedDeskId}
                    />
                  )}
                </TabPanel>
                {/* TAB 3 */}
                <TabPanel value={tabValue} index={IBAN_TAB}>
                  {banks && (
                    <PanelIban
                      bank={
                        banks[selectedBankIndex]?.aspsps[selectedDeskIndex]?.approach.length === 1
                          ? banks[selectedBankIndex].aspsps[selectedDeskIndex]
                          : banks[0]
                      }
                      setValidationEnabled={setValidationEnabled}
                      setIban={setIban}
                      iban={iban}
                    />
                  )}
                </TabPanel>
              </div>
              <ModalFooter
                paymentDetails={paymentDetails}
                tabValue={tabValue}
                validationEnabled={validationEnabled}
                submitPayment={submitPayment}
                client={client}
                pisp={pisp}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
