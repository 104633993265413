import React from 'react';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { getLogout } from '../../../api/authenticationService';
import { useAuth } from '../../../context/AuthContext';
import { LOGIN_PAGE } from '../../../routes/ConstantsRoutes';
import Navigation from '../Navigation/Navigation';
import classes from './MainHeader.module.css';
const MainHeader = () => {
  const navigate = useNavigate();

  const { authenticationInfo, tokenInfo, logoutHandler } = useAuth();

  const Logout = () => {
    mutate();
    logoutHandler();
  };

  const Login = () => {
    navigate(LOGIN_PAGE);
  };

  const { mutate } = useMutation(['authGaap'], async () => await getLogout(), {
    onSuccess: () => {},
    refetchOnWindowFocus: false,
    enabled: false
  });

  return (
    <header className={classes['main-header']}>
      <Link to="/">
        <h1>Extension Hub Pisp</h1>
      </Link>
      <Navigation
        isAuthenticated={authenticationInfo?.isLoggedIn}
        isAdmin={tokenInfo?.role === 'ADMIN' || tokenInfo?.role === 'ROLE_ADMIN'}
        Login={Login}
        Logout={Logout}
      />
    </header>
  );
};

export default MainHeader;
