import React, { useState } from 'react';
import InputFilter from '../../atoms/Filter/InputFilter';
import MontantFilter from '../../atoms/Filter/MontantFilter';
import PaymentTypeFilter from '../../atoms/Filter/PaymentTypeFilter';
import StatusFilter from '../../atoms/Filter/StatusFilter';

export default function FiltersInitiation({ setFilter, isFetching }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters] = useState(new Map());
  let intervalId;

  const filter = (name, value) => {
    if (filters.has(name)) {
      filters.delete(name);
    }
    filters.set(name, value);
    const obj = new Object();
    filters.forEach((value, key) => (obj[key] = value));
    setFilter(obj);
  };

  const performSeek = (name, value) => {
    let counter = 1;
    if (intervalId) clearInterval(intervalId);
    intervalId = setInterval(() => {
      counter = counter - 1;
      if (counter === 0) {
        filter(name, value);
        clearInterval(intervalId);
      }
    }, 1000);
  };

  const filterMapper = (filters) => {
    let codes = [];
    filters.forEach((element) => {
      switch (element) {
        case 'NONE':
          codes.push('NONE');
          break;
        case 'REJETÉ':
          codes.push('RJCT');
          break;
        case 'EXÉCUTÉ':
          codes.push('ACSC');
          break;
        default:
          codes = codes.concat(['ACCP', 'ACSP', 'ACFC', 'RCVD', 'PDNG', 'ACTC']);
      }
    });
    return codes;
  };

  return (
    <div>
      <h3 className="filters-title">Filtrez vos initiations par :</h3>
      <div className="filters">
        <StatusFilter
          label="Statut"
          setFilter={(name, filters) => {
            filter(name, filterMapper(filters));
          }}
          name="transactionStatus"
          isFilterOpen={isFilterOpen}
          isFetching={isFetching}
          setIsFilterOpen={setIsFilterOpen}
        />
        <PaymentTypeFilter
          label="Type de paiement"
          setFilter={filter}
          name="type"
          isFilterOpen={isFilterOpen}
          isFetching={isFetching}
          setIsFilterOpen={setIsFilterOpen}
        />
        <InputFilter
          label="Resource Id"
          setFilter={performSeek}
          name="resourceId"
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
        <InputFilter
          label="End to End ID"
          setFilter={performSeek}
          name="endToEnd"
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
        <InputFilter
          label="Remittance"
          setFilter={performSeek}
          name="remittanceInformation"
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
        <InputFilter
          label="BIC banque du client"
          setFilter={performSeek}
          name="bicDebtor"
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
        <MontantFilter
          label="Montant"
          setFilter={performSeek}
          name="formatAmount"
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
        <InputFilter
          label="Dèpartement"
          setFilter={performSeek}
          name="department"
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
      </div>
    </div>
  );
}
