import React, { useState } from 'react';

import Button from '../../atoms/Button/Button';
import Card from '../../atoms/Card/Card';

import { Link } from 'react-router-dom';
import classes from './Login.module.css';

import CircularProgress from '@mui/material/CircularProgress';

const Login = (props) => {
  const [enteredIdentification, setIdentificatin] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState();

  const identificationChangeHandler = (event) => {
    setIdentificatin(event.target.value);
  };

  const passwordChangeHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  const validatePasswordHandler = () => {
    setPasswordIsValid(enteredPassword.trim());
  };

  const LoginJwt = () => {
    props.onLoginJwt(enteredIdentification, enteredPassword);
  };

  const LoginGaap = () => {
    props.onLoginGaap();
  };

  return (
    <Card className={classes.login}>
      <div className={`${classes.control}`}>
        <label htmlFor="identification">Identification</label>
        <input
          type="identification"
          id="identification"
          value={enteredIdentification}
          onChange={identificationChangeHandler}
        />
      </div>
      <div className={`${classes.control} ${passwordIsValid === false ? classes.invalid : ''}`}>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          value={enteredPassword}
          onChange={passwordChangeHandler}
          onBlur={validatePasswordHandler}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              LoginJwt();
            }
          }}
        />
      </div>
      <Link className={`${classes.text}`} to="#" onClick={LoginGaap}>
        Login with SSO
      </Link>
      {props.error && !props.isLoading ? (
        <p className={`${classes.error}`}>Error on connection</p>
      ) : (
        <p className={`${classes.error}`}></p>
      )}
      <div className={classes.actions}>
        <Button className={classes.btn} onClick={LoginJwt} disabled={props.isLoading}>
          {props.isLoading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
        </Button>
      </div>
    </Card>
  );
};

export default Login;
