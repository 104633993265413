import React from 'react';
import { config } from '../../../config';

const CguPdfLink = ({ coid }) => {
  if (coid) {
    return (
      <a target="_blank" href={`${config.cguPdfUrl}?coid=${coid}`} rel="noreferrer">
        <span style={{ color: 'blue' }}>conditions générales d’utilisation</span>
      </a>
    );
  }
  return null;
};

const CguComponent = ({ pisp, validationEnabled, submitPayment }) => {
  return (
    <div>
      <p className="footer-row">
        <span
          className="footer-info phrase"
          style={{ fontWeight: '400', fontSize: '11px', fontStyle: 'italic', textAlign: 'center' }}
        >
          En poursuivant votre parcours de paiement par virement, vous reconnaissez avoir pris
          connaissance des <CguPdfLink coid={pisp?.coid} /> du service d’initiation de paiement par
          virement fourni par la <span>{pisp?.name}</span>, en accepter les termes et y adhérer sans
          réserve.Vous donnez ainsi votre consentement à l’initiation du présent virement sur votre
          compte que vous aurez désigné.
        </span>
      </p>
      <p>
        <button
          className="bpce-button bpce-button-primary"
          style={{
            width: '100%',
            maxWidth: 'initial'
          }}
          disabled={!validationEnabled ? 'disabled' : ''}
          onClick={submitPayment}
        >
          <span>Valider</span>
        </button>
      </p>
    </div>
  );
};

export { CguComponent };
