import React, { useState } from 'react';

import { ReactComponent as ValidLogo } from '../../../assets/icons/svg/icon_check.svg';
import { ReactComponent as InvalidLogo } from '../../../assets/icons/svg/icon_close.svg';
import { ReactComponent as LockLogo } from '../../../assets/icons/svg/picto_action_cadenas.svg';
import Button from '../../atoms/Button/Button';
import Card from '../../atoms/Card/Card';
import './UpdatePassword.scss';

const UpdatePassword = (props) => {
  const [enteredPassword, setEnteredPassword] = useState('');
  const [enteredPreviousPassword, setPreviousEnteredPassword] = useState('');
  const [enteredPasswordConfirmation, setEnteredPasswordConfirmation] = useState('');

  const passwordChangeHandler = (event) => {
    setEnteredPassword(event.target.value);
  };
  const previousPasswordChangeHandler = (event) => {
    setPreviousEnteredPassword(event.target.value);
  };

  const passwordConfirmationChangeHandler = (event) => {
    setEnteredPasswordConfirmation(event.target.value);
  };

  const containsUppercase = () => {
    return /[A-Z]/.test(enteredPassword);
  };

  const containsSmallcase = () => {
    return /[a-z]/.test(enteredPassword);
  };

  const samePassword = () => {
    return enteredPassword === enteredPasswordConfirmation;
  };

  const containsSpecialCaracter = () => {
    var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    return format.test(enteredPassword);
  };

  const containsTwelvesCharacters = () => {
    return /.{12,}/.test(enteredPassword);
  };

  const validatePasswordHandler = () => {
    return containsUppercase && containsSmallcase && samePassword && containsSpecialCaracter;
  };

  const validatePasswordConfirmationHandler = () => {
    return validatePasswordHandler && samePassword;
  };

  const submitHandler = (event) => {
    event.preventDefault();
    props.onPasswordModification(enteredPreviousPassword, enteredPassword);
  };

  return (
    <Card className="updatePassword">
      <form onSubmit={submitHandler}>
        <h2>Password Modification</h2>
        <div className="flex">
          <label htmlFor="password">Previous Password</label>
          <input
            type="password"
            id="password"
            value={enteredPreviousPassword}
            onChange={previousPasswordChangeHandler}
          />
        </div>
        <div className="flex">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={enteredPassword}
            onChange={passwordChangeHandler}
            onBlur={validatePasswordHandler}
            className={validatePasswordHandler() ? '' : 'invalid'}
          />
        </div>
        <div className="flex">
          <label htmlFor="password">Password Confirmation</label>
          <input
            type="password"
            id="password"
            value={enteredPasswordConfirmation}
            onChange={passwordConfirmationChangeHandler}
            onBlur={validatePasswordConfirmationHandler}
            className={validatePasswordConfirmationHandler() ? '' : 'invalid'}
          />
        </div>

        <div className="validation-area">
          <h3>The new password must match the following criteria: </h3>
          <div className="flex-content">
            <LockLogo className="validation-logo" />
            <div className="validation-tests">
              {containsTwelvesCharacters() ? (
                <p className="valid">
                  <ValidLogo className="valid-logo" />
                  Must contain at least 12 characters
                </p>
              ) : (
                <p className="invalid">
                  {' '}
                  <InvalidLogo className="invalid-logo" />
                  Must contain at least 12 characters
                </p>
              )}
              {containsUppercase() ? (
                <p className="valid">
                  <ValidLogo className="valid-logo" />
                  Must contain at least 1 letter in capital case
                </p>
              ) : (
                <p className="invalid">
                  {' '}
                  <InvalidLogo className="invalid-logo" />
                  Must contain at least 1 letter in capital case
                </p>
              )}
              {containsSmallcase() ? (
                <p className="valid">
                  <ValidLogo className="valid-logo" />
                  Must contain at least 1 letter in small case
                </p>
              ) : (
                <p className="invalid">
                  {' '}
                  <InvalidLogo className="invalid-logo" />
                  Must contain at least 1 letter in small case
                </p>
              )}
              {containsSpecialCaracter() ? (
                <p className="valid">
                  <ValidLogo className="valid-logo" />
                  Must contain at least 1 special character
                </p>
              ) : (
                <p className="invalid">
                  {' '}
                  <InvalidLogo className="invalid-logo" />
                  Must contain at least 1 special character
                </p>
              )}
              {samePassword() ? (
                <p className="valid">
                  <ValidLogo className="valid-logo" />
                  The "Password" and "Password confirmation" field must match
                </p>
              ) : (
                <p className="invalid">
                  {' '}
                  <InvalidLogo className="invalid-logo" />
                  The "Password" and "Password confirmation" field must match
                </p>
              )}
            </div>
          </div>
        </div>
        {props.error ? <p className="error">Error on modification</p> : <p></p>}
        <div className="action">
          <Button type="submit" className="btn" disabled={!validatePasswordConfirmationHandler()}>
            Change Password
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default UpdatePassword;
