import React, { useEffect, useState } from 'react';

import '../../../assets/scss/card/src/index.scss';
import '../../../assets/scss/infobox/src/index.scss';
import '../../../assets/scss/input/src/index.scss';
import '../../organism/Modal/Modal.css';
import './PanelIban.css';

export default function PanelIban({ setValidationEnabled, bank, iban, setIban }) {
  const [ibanValid, isValid] = useState(true);
  const ibanValidador = require('iban');

  function eraseInput() {
    setIban('');
    isValid(true);
  }

  const validateIban = (iban) => {
    if (iban === '') {
      isValid(true);
    } else {
      isValid(ibanValidador.isValid(iban));
      setValidationEnabled(ibanValidador.isValid(iban));
    }
  };

  useEffect(() => {
    setValidationEnabled(ibanValidador.isValid(iban));
  }, [iban, setValidationEnabled]);

  const showIBANError = () => {
    return (
      <span id="input-4-msg" className="bpce-input-error-msg bpce-input-display-column">
        <span className="bpce-icon-font erase" aria-hidden="true" />
        <div className="align-iban-error-info">"Le format de l'IBAN est invalide"</div>
      </span>
    );
  };

  return (
    <div className="bpce-row row-center">
      <p className="searchField-label">Depuis quelle banque souhaitez-vous payer ?</p>

      <div>
        <div className="bpce-card bpce-card-bankCard bpce-card-bankCard-large bpce-card-bankCard-large-height bpce-card-bankCard-solo">
          <img
            className="bpce-card-bankCard-icon"
            src={`data:image/svg+xml;base64,${bank.logo}`}
            alt="logo"
          />
          <span className="bpce-card-bankCard-label">{bank.name}</span>
        </div>
      </div>
      <div>
        <div className="bpce-infobox bpce-infobox-secondary bpce-card-bankCard-large ">
          <div className="bpce-infobox-header">
            <span className="bpce-icon-font information"></span>
            <p className="bpce-infobox-title">Pouvez-vous indiquer notre IBAN ?</p>
          </div>
          <p className="bpce-infobox-content ">Votre banque demande la saisie de votre IBAN.</p>
        </div>
      </div>
      <div>
        <p>
          <label htmlFor="input-1" className="bpce-input">
            <input
              id="input-1"
              aria-label="IBAN"
              className={`iban-input bpce-input-field ${
                ibanValid === true ? '' : 'bpce-input-field-error-all-border'
              }`}
              type="text"
              placeholder=" "
              value={iban}
              onInput={(e) => validateIban(e.target.value)}
              onChange={(e) => setIban(e.target.value)}
            />
            <span className="bpce-input-label" aria-hidden="true">
              IBAN
            </span>
            <button className="bpce-input-icon" aria-label="Erase text" onClick={eraseInput}>
              <span className="bpce-icon-font erase" aria-hidden="true"></span>
            </button>

            {!ibanValid && showIBANError()}
          </label>
        </p>
      </div>
    </div>
  );
}
