import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { config } from '../config.js';
import { AuthProvider } from '../context/AuthContext';
import BanksPage from '../pages/BanksPage/BanksPage';
import ActivatedBanksPage from '../pages/ClientPage/ActivatedBanksPage.js';
import SettingsPage from '../pages/ClientPage/SettingsPage';
import DashboardPage from '../pages/DashboardPage/DashboardPage';
import Executed from '../pages/Executed/Executed';
import GaapCallbackLoginPage from '../pages/GaapCallbackLoginPage/GaapCallbackLoginPage';
import GaapCallbackLogoutPage from '../pages/GaapCallbackLogoutPage/GaapCallbackLogoutPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import MainPage from '../pages/MainPage/MainPage';
import PaymentGeneratorPage from '../pages/PaymentGeneratorPage/PaymentGeneratorPage';
import PaymentLinkPage from '../pages/PaymentLinkPage/PaymentLinkPage.js';
import Successful from '../pages/Successful/Successful';
import Unsuccessful from '../pages/Unsuccessful/Unsuccessful';
import UpdatePasswordPage from '../pages/UpdatePasswordPage/UpdatePasswordPage';
import {
  ACTIVATED_BANKS,
  CONSENT_BANK_PAGE,
  CONSENT_EXEC_EXPIRED,
  CONSENT_SUCCESSFUL,
  CONSENT_UNSUCCESSFUL,
  DASHBOARD_PAGE,
  DEFAULT_PAGE,
  GAAP_CALLBACK_LOGIN,
  GAAP_CALLBACK_LOGOUT,
  HOME_PAGE,
  LOGIN_PAGE,
  PAYMENT_LINK_GENERATOR_PAGE,
  PAYMENT_LINK_LIST_PAGE,
  SETTINGS_PAGE,
  UPDATE_PASSWORD_PAGE
} from './ConstantsRoutes.js';
import RoleAuthRoute from './RoleAuthRoute';

const RouteConfig = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path={DEFAULT_PAGE} element={<MainPage />} />
            <Route path={HOME_PAGE} element={<MainPage />} />
            <Route path={LOGIN_PAGE} element={<LoginPage />} />
            <Route
              path={DASHBOARD_PAGE}
              element={
                <RoleAuthRoute roles={['USER_ADMIN', 'ADMIN', 'STANDARD_USER']}>
                  <DashboardPage />
                </RoleAuthRoute>
              }
            />
            <Route
              path={UPDATE_PASSWORD_PAGE}
              element={
                <RoleAuthRoute roles={['USER_ADMIN', 'ADMIN', 'STANDARD_USER']}>
                  <UpdatePasswordPage />
                </RoleAuthRoute>
              }
            />
            {/* <Route path="/Register" element={<Register />} /> */}
            <Route path={CONSENT_BANK_PAGE} element={<BanksPage />} />
            <Route path={GAAP_CALLBACK_LOGIN} element={<GaapCallbackLoginPage />} />
            <Route path={GAAP_CALLBACK_LOGOUT} element={<GaapCallbackLogoutPage />} />
            <Route path={CONSENT_SUCCESSFUL} element={<Successful />} />
            <Route path={CONSENT_UNSUCCESSFUL} element={<Unsuccessful />} />
            <Route path={CONSENT_EXEC_EXPIRED} element={<Executed />} />
            <Route
              path={SETTINGS_PAGE}
              element={
                <RoleAuthRoute roles={['USER_ADMIN', 'ADMIN']}>
                  <SettingsPage />
                </RoleAuthRoute>
              }
            />
            <Route
              path={ACTIVATED_BANKS}
              element={
                <RoleAuthRoute roles={['USER_ADMIN', 'ADMIN', 'STANDARD_USER']}>
                  <ActivatedBanksPage />
                </RoleAuthRoute>
              }
            />
            {JSON.parse(config.showPaymentMock) && (
              <Route
                path={PAYMENT_LINK_GENERATOR_PAGE}
                element={
                  <RoleAuthRoute roles={['ADMIN']}>
                    <PaymentGeneratorPage />
                  </RoleAuthRoute>
                }
              />
            )}
            <Route
              path={PAYMENT_LINK_LIST_PAGE}
              element={
                <RoleAuthRoute roles={['USER_ADMIN', 'ADMIN', 'STANDARD_USER']}>
                  <PaymentLinkPage />
                </RoleAuthRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
};
export default RouteConfig;
