import axiosInstance from '../util/AxiosInterceptor';
import { PAYMENT_LINK_WEB_URL } from './ConstantsURL';

export const createPaymentLink = async (payload) => {
  const { data } = await axiosInstance.post(PAYMENT_LINK_WEB_URL, payload);
  return data;
};

export const retrievepaymentInitiation = async (resourceId) => {
  const { data } = await axiosInstance.get(`${PAYMENT_LINK_WEB_URL}/${resourceId}`);
  return data;
};
