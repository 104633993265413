import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { LOGIN_PAGE } from '../../routes/ConstantsRoutes';
const GaapCallbackLogoutPage = () => {
  const { logoutHandler } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    logoutHandler();
    navigate(LOGIN_PAGE);
  });

  return <React.Fragment />;
};

export default GaapCallbackLogoutPage;
