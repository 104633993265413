import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { HOME_PAGE, LOGIN_PAGE, UPDATE_PASSWORD_PAGE } from './ConstantsRoutes';
const RoleBasedRoute = ({ children, roles }) => {
  const { userInfo, authenticationInfo } = useAuth();

  if (!authenticationInfo?.isLoggedIn) {
    return <Navigate to={LOGIN_PAGE} />;
  } else if ((userInfo?.actionsRequired?.length !== 0) & userInfo?.actionsRequired) {
    return <Navigate to={UPDATE_PASSWORD_PAGE} />;
  } else if (!roles.includes(userInfo.role)) {
    return <Navigate to={HOME_PAGE} />;
  } else {
    return children;
  }
};

export default RoleBasedRoute;
