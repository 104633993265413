import React, { useEffect, useRef, useState } from 'react';

import { useGlobalFilter, usePagination, useTable } from 'react-table';

import * as R from 'ramda';

import Pagination from '../../atoms/Pagination/Pagination';
import DatePickerRange from '../../molecules/Datepicker/DatePickerRange';
import Panel from '../../molecules/Panel/Panel';

import { LinearProgress } from '@mui/material';
import { config } from '../../../config';
import Filters from '../../molecules/Filters/Filters';
import './SuiviContent.scss';

export default function SuiviContent({
  data,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  refetch,
  isFetching,
  columnsDefinition,
  filterConstant,
  resourceIdProperty
}) {
  const columns = React.useMemo(() => columnsDefinition, []);

  const filterTypes = React.useMemo(() => ({}), []);
  const [selectedRow, setSelectedRow] = useState();
  const [panelOpen, setPanelOpen] = useState(false);

  const panelRef = useRef();

  const [resourceId, setResourceId] = useState(null);
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data: data.content,
      filterTypes,
      initialState: data.pageable
    },
    useGlobalFilter,
    usePagination
  );

  useEffect(() => {
    panelRef.current.closePanel();
  }, [page]);

  const handleClick = (index, resourceId) => {
    panelRef.current.closePanel();
    setSelectedRow(index);
    setResourceId(resourceId);
    panelRef.current.togglePanel();
  };

  const classNameForRow = (index) => {
    let classNames = '';
    if (panelOpen === false) classNames += 'tabLine';
    if (index === selectedRow && panelOpen) classNames += ' tabLine-selected';
    return classNames;
  };

  const paginate = (index, sort = data.pageable.sort, filter) => {
    const page = {
      pageNumber: 0,
      pageSize: config.defaultPageSize,
      sort: {
        id: 'creationDateTime',
        desc: true
      }
    };
    if (index !== undefined) {
      page.pageNumber = index;
      page.sort = sort;
    }
    refetch(page, filter);
  };

  const filter = (param) => {
    paginate(data.pageable.pageNumber, data.pageable.sort, param);
  };

  return (
    <div>
      <Panel
        ref={panelRef}
        panelOpen={panelOpen}
        setPanelOpen={setPanelOpen}
        resourceId={resourceId}
      />

      <DatePickerRange
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        refetch={paginate}
        isFetching={isFetching}
      />

      <Filters type={filterConstant} setFilter={filter} isFetching={isFetching} />

      <div className="bpce-datatable bpce-datatable-striped">
        <div className="bpce-datatable-container body-display">
          <table {...getTableProps()} className="init-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="bpce-datatable-aleft"
                      {...column.getHeaderProps({
                        style: {
                          minWidth: column.minWidth,
                          width: column.width
                        }
                        //column.getSortByToggleProps()
                      })}
                      onClick={() => {
                        let property = column.id;
                        if (property === 'formatAmount') {
                          property = 'amount';
                        }
                        paginate(data.pageable.pageNumber, {
                          id: property,
                          desc: !data.pageable.sort.desc
                        });
                      }}
                    >
                      <div className="datatable-header-with-sort">
                        {column.render('Header')}
                        {column.id === data.pageable.sort.id ||
                        (column.id === 'formatAmount' && data.pageable.sort.id === 'amount') ? (
                          data.pageable.sort.desc ? (
                            <div className="arrows">
                              <div className="arrow arrow-up arrow-up-off" />
                              <div className="arrow arrow-down arrow-down-on" />
                            </div>
                          ) : (
                            <div className="arrows">
                              <div className="arrow arrow-up arrow-up-on" />
                              <div className="arrow arrow-down arrow-down-off" />
                            </div>
                          )
                        ) : (
                          <div className="arrows">
                            <div className="arrow arrow-up arrow-up-off" />
                            <div className="arrow arrow-down arrow-down-off" />
                          </div>
                        )}
                      </div>
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {isFetching && (
                <tr>
                  <td style={{ padding: 0, margin: 0 }}>
                    <LinearProgress
                      color="secondary"
                      style={{ position: 'absolute', width: '100%' }}
                    />
                  </td>
                </tr>
              )}
              {page.length > 0 &&
                page.map((row, i) => {
                  prepareRow(row);
                  const myClassName = classNameForRow(i);
                  return (
                    <tr
                      onClick={() => {
                        const resourceId = row.values[resourceIdProperty];
                        if (resourceId) {
                          handleClick(i, row.values[resourceIdProperty]);
                        }
                      }}
                      className={`${myClassName}`}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                      })}
                    </tr>
                  );
                })}
              {R.range(0, config.defaultPageSize - data.totalElements).map((i, index) => (
                <tr key={index}>
                  {R.range(0, columns.length).map((j, index) => (
                    <td key={index}>&nbsp;</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination">
            <Pagination
              gotoPage={paginate}
              pageCount={data.totalPages}
              forcePage={data.pageable.pageNumber}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
