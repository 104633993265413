import axiosInstance from '../util/AxiosInterceptor';
import { CONSENT_URL, CONSENT_WEBCONNECT_URL } from './ConstantsURL';

export const retrieveAspsp = async (resourceId) => {
  const { data } = await axiosInstance.get(`${CONSENT_URL}/${resourceId}`);
  return data;
};

export const postPaymentInitiation = async (resourceId, payload) => {
  const { data } = await axiosInstance.post(`${CONSENT_WEBCONNECT_URL}/${resourceId}`, payload);
  return { data };
};

export const retrievePisp = async (appId) => {
  if (appId) {
    const { data } = await axiosInstance.get(`pisp/${appId}`);
    return data;
  }
  return null;
};
