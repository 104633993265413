import React from 'react';

import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { updatePassword } from '../../api/userService';
import MainHeader from '../../components/molecules/MainHeader/MainHeader';
import UpdatePassword from '../../components/organism/UpdatePassword/UpdatePassword';
import { useAuth } from '../../context/AuthContext';
import { DASHBOARD_PAGE } from '../../routes/ConstantsRoutes';
const UpdatePasswordPage = () => {
  const { tokenInfo, storeUserInfo } = useAuth();
  const [errorconnection, setErrrorConnection] = useState(false);
  const navigate = useNavigate();

  const onPasswordModification = async (previousPassword, password) => {
    mutate({
      idUser: tokenInfo?.userId,
      currentPassword: previousPassword,
      newPassword: password
    });
  };

  const { mutate } = useMutation(
    ['auth'],
    async ({ idUser, currentPassword, newPassword }) =>
      await updatePassword(idUser, currentPassword, newPassword),
    {
      onSuccess: (data) => {
        storeUserInfo(data);
        navigate(DASHBOARD_PAGE);
      },
      onError: () => {
        setErrrorConnection(true);
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  return (
    <React.Fragment>
      <MainHeader />
      <main>
        <UpdatePassword onPasswordModification={onPasswordModification} error={errorconnection} />
      </main>
    </React.Fragment>
  );
};

export default UpdatePasswordPage;
