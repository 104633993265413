export const DEFAULT_PAGE = '/';
export const HOME_PAGE = '/home';
export const LOGIN_PAGE = '/login';
export const REGISTER_PAGE = '/register';
export const PAYMENT_LINK_GENERATOR_PAGE = '/payment';
export const DASHBOARD_PAGE = '/dashboard';
export const UPDATE_PASSWORD_PAGE = '/updatePassword';
export const CONSENT_BANK_PAGE = '/payment/:resourceId';
export const GAAP_CALLBACK_LOGIN = '/gaap/callback/login';
export const GAAP_CALLBACK_LOGOUT = '/gaap/callback/logout';
export const CONSENT_SUCCESSFUL = '/successful';
export const CONSENT_EXEC_EXPIRED = '/expired';
export const CONSENT_UNSUCCESSFUL = '/unsuccessful';
export const SETTINGS_PAGE = '/settings';
export const ACTIVATED_BANKS = '/activated-banks';
export const PAYMENT_LINK_LIST_PAGE = '/payment-link';
