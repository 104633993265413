import { Alert, Snackbar, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { patchClient } from '../../api/clientService';
import '../../assets/scss/status-label/index.scss';
import { useAuth } from '../../context/AuthContext';
import './ClientComponent.scss';

const ClientComponent = (props) => {
  const client = props.client;
  const { tokenInfo } = useAuth();
  const tagline = client.tagline;
  const image = client.image;
  const status = client.status;
  const appId = client.appId;
  const accounts = client.accounts;
  const role = client.role;

  const [key, setkey] = useState(client.clientCredentials?.key || '');
  const [secret, setSecret] = useState(client.clientCredentials?.secret || '');
  const [secretFieldType, setSecretFieldType] = useState('password');
  const [successfulUrl, setSuccessfulUrl] = useState(client.reportUrls?.successful);
  const [unsuccessfulUrl, setUnsuccessfulUrl] = useState(client.reportUrls?.unsuccessful);
  const [expireIn, setExpireIn] = useState(client.consentInfo ? client.consentInfo.expireIn : '');
  const [openSuccessNotification, setOpenSuccessNotification] = React.useState(false);
  const [openFailureNotification, setOpenFailureNotification] = React.useState(false);

  const { mutate } = useMutation(
    ['updateClient'],
    async ({ clientDetails }) => await patchClient(tokenInfo?.clientId, clientDetails),
    {
      onSuccess: (client) => {
        if (client !== undefined) {
          setOpenSuccessNotification(true);
        }
      },
      onError: () => {
        setOpenFailureNotification(true);
      },
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  const saveLinkParameters = async (event) => {
    event.preventDefault();
    const clientDetails = {
      reportUrls: {
        successful: successfulUrl,
        unsuccessful: unsuccessfulUrl
      },
      consentInfo: {
        expireIn: expireIn,
        unit: 'SECONDS'
      }
    };
    mutate({ clientDetails });
  };

  const saveCredentialParameters = async (event) => {
    event.preventDefault();
    const clientDetails = {
      clientCredentials: {
        key: key,
        secret: secret
      }
    };
    mutate({ clientDetails });
  };

  const keyChangeHandler = (event) => {
    setkey(event.target.value);
  };

  const eraseKey = () => {
    setkey('');
  };

  const secretChangeHandler = (event) => {
    setSecret(event.target.value);
  };

  const successfulUrlChangeHandler = (event) => {
    setSuccessfulUrl(event.target.value);
  };

  const eraseSuccessful = () => {
    setSuccessfulUrl('');
  };

  const unsuccessfulUrlChangeHandler = (event) => {
    setUnsuccessfulUrl(event.target.value);
  };

  const eraseUnsuccessful = () => {
    setUnsuccessfulUrl('');
  };

  const expireInChangeHandler = (event) => {
    setExpireIn(event.target.value);
  };

  const eraseExpireIn = () => {
    setExpireIn('');
  };

  const toggleSecretFieldType = () => {
    if (secretFieldType === 'password') {
      setSecretFieldType('text');
      return;
    }
    setSecretFieldType('password');
  };

  const handleCloseSuccessNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessNotification(false);
  };

  const handleCloseFailureNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenFailureNotification(false);
  };

  return (
    <React.Fragment>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          open={openSuccessNotification}
          autoHideDuration={6000}
          onClose={handleCloseSuccessNotification}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          key={'success'}
        >
          <Alert onClose={handleCloseSuccessNotification} severity="success" sx={{ width: '100%' }}>
            Sauvegarde réussie !
          </Alert>
        </Snackbar>
        <Snackbar
          open={openFailureNotification}
          autoHideDuration={6000}
          onClose={handleCloseFailureNotification}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          key={'failure'}
        >
          <Alert onClose={handleCloseFailureNotification} severity="error" sx={{ width: '100%' }}>
            Sauvegarde échoué!
          </Alert>
        </Snackbar>
      </Stack>

      <main>
        <div className="main-content-wrapper">
          <div className="second-content-wrapper">
            <div className="logo-tagline-status">
              <img className="client-image" src={`data:image/svg+xml;base64,${image}`} alt="logo" />
              <div className="tagline-status">
                <span>{tagline}</span>
                <span className="bpce-status-label bpce-status-label-success">{status}</span>
              </div>
            </div>
            <div className="main-frame">
              <span className="main-frame-title">Paramètres de lien</span>
              <div className="settings-frame">
                <div className="bpce-input-url-success">
                  <label htmlFor="input-url-success" className="bpce-input">
                    <input
                      id="input-url-success"
                      className="bpce-input-field client"
                      type="text"
                      value={successfulUrl}
                      onChange={successfulUrlChangeHandler}
                    />
                    <span className="bpce-input-label" aria-hidden="true">
                      Lien de renvoi en cas de succès
                    </span>
                    <button
                      className="bpce-input-icon"
                      aria-label="Erase text"
                      onClick={eraseSuccessful}
                    >
                      <span className="bpce-icon-font close" aria-hidden="true"></span>
                    </button>
                  </label>
                </div>
                <div className="bpce-input-url-failure">
                  <label htmlFor="input-url-failure" className="bpce-input">
                    <input
                      id="input-url-failure"
                      className="bpce-input-field client"
                      type="text"
                      value={unsuccessfulUrl}
                      onChange={unsuccessfulUrlChangeHandler}
                    />
                    <span className="bpce-input-label" aria-hidden="true">
                      Lien de renvoi en cas d’échec
                    </span>
                    <button
                      className="bpce-input-icon"
                      aria-label="Erase text"
                      onClick={eraseUnsuccessful}
                    >
                      <span className="bpce-icon-font close" aria-hidden="true"></span>
                    </button>
                  </label>
                </div>
                <div className="bpce-input-url-life-time">
                  <label htmlFor="input-url-life-time" className="bpce-input">
                    <input
                      id="input-url-life-time"
                      className="bpce-input-field client"
                      type="number"
                      value={expireIn}
                      onChange={expireInChangeHandler}
                    />
                    <span className="bpce-input-label" aria-hidden="true">
                      Durée de vie du lien par défaut (en seconde)
                    </span>
                    <button
                      className="bpce-input-icon"
                      aria-label="Erase text"
                      onClick={eraseExpireIn}
                    >
                      <span className="bpce-icon-font close" aria-hidden="true"></span>
                    </button>
                  </label>
                </div>
                <button
                  type="button"
                  className="bpce-button bpce-button-secondary"
                  aria-label="Bouton de fermeture"
                  onClick={saveLinkParameters}
                >
                  Enregistrer les nouveaux paramètres de lien
                </button>
              </div>
            </div>
            {role === 'ADMIN' && (
              <div className="main-frame credentials">
                <div className="main-frame-title">Identifiants API</div>
                <div className="settings-frame">
                  <div className="bpce-input-key">
                    <p>
                      <label htmlFor="input-key" className="bpce-input">
                        <input
                          id="input-key"
                          className="bpce-input-field client"
                          type="text"
                          value={key}
                          onChange={keyChangeHandler}
                        />
                        <span className="bpce-input-label" aria-hidden="true">
                          Client key
                        </span>
                        <button
                          className="bpce-input-icon"
                          aria-label="Erase text"
                          onClick={eraseKey}
                        >
                          <span className="bpce-icon-font close" aria-hidden="true"></span>
                        </button>
                      </label>
                    </p>
                  </div>

                  <div className="bpce-input-secret">
                    <p>
                      <label htmlFor="input-secret" className="bpce-input">
                        <input
                          id="input-secret"
                          className="bpce-input-field client"
                          type={secretFieldType}
                          value={secret}
                          onChange={secretChangeHandler}
                        />
                        <span className="bpce-input-label" aria-hidden="true">
                          Secret key
                        </span>
                        <button
                          className="bpce-input-icon"
                          aria-label="Erase text"
                          onClick={toggleSecretFieldType}
                        >
                          <span className="bpce-icon-font eye" aria-hidden="true"></span>
                        </button>
                      </label>
                    </p>
                  </div>

                  <div className="bpce-input-app-id">
                    <p>
                      <label htmlFor="input-app-id" className="bpce-input">
                        <input
                          id="input-app-id"
                          className="bpce-input-field client"
                          type="text"
                          value={appId}
                          disabled
                        />
                        <span className="bpce-input-label" aria-hidden="true">
                          App Id
                        </span>
                      </label>
                    </p>
                  </div>

                  <div>
                    <button
                      type="button"
                      className="bpce-button bpce-button-secondary"
                      aria-label="Bouton de fermeture"
                      onClick={saveCredentialParameters}
                    >
                      Enregistrer les nouvelles configuration
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="main-frame creditor-account">
              <div className="main-frame-title">Comptes à créditer</div>
              <div className="bpce-datatable bpce-datatable-striped">
                <div className="bpce-datatable-container body-display">
                  <table className="init-table">
                    <thead>
                      <tr>
                        <th className="bpce-datatable-aleft" scope="col">
                          <span>BIC</span>
                        </th>
                        <th className="bpce-datatable-aleft" scope="col">
                          <span>IBAN</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {accounts.map((account) => {
                        return (
                          <tr className="tabLine">
                            <td className="bpce-datatable-aleft" scope="col">
                              {account.bic}
                            </td>
                            <td className="bpce-datatable-aleft" scope="col">
                              {account.iban}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default ClientComponent;
