import { INITIATION, PAYMENT_LINK } from './ConstantsFilter';
import FiltersInitiation from './FiltersInitiation';
import FiltersPaymentLinkList from './FiltersPaymentLinkList';

export default function Filters({ type, setFilter, isFetching }) {
  switch (type) {
    case INITIATION:
      return <FiltersInitiation setFilter={setFilter} isFetching={isFetching} />;
    case PAYMENT_LINK:
      return <FiltersPaymentLinkList setFilter={setFilter} isFetching={isFetching} />;
    default:
      return <div></div>;
  }
}
