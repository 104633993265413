import fr from 'date-fns/locale/fr';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../assets/scss/loader/src/index.scss';
import './datePicker.scss';

export default function DatePickerRange({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  refetch,
  isFetching
}) {
  const LogoInput = React.forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="bpce-datepicker-button" onClick={onClick} ref={ref}>
      <div>{value}</div>
      <div className="bpce-datepicker-logo bpce-icon-font calendar"></div>
    </button>
  ));

  registerLocale('fr', fr);

  return (
    <div className="datepicker-range">
      <div>De</div>
      <div>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          locale={fr}
          dateFormat="dd-MM-yyyy"
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
          customInput={<LogoInput />}
          disabledKeyboardNavigation
        />
      </div>
      <div>À</div>
      <div>
        <DatePicker
          id="end-date-range"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          locale={fr}
          dateFormat="dd-MM-yyyy"
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={new Date()}
          customInput={<LogoInput />}
          disabledKeyboardNavigation
        />
      </div>
      <div
        className="bpce-button bpce-button-primary bpce-button-icon-only"
        onClick={() => refetch()}
      >
        {isFetching && (
          <div className="bpce-loader">
            <svg
              className="bpce-loader-svg bpce-loader-svg-custom"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <circle cx="50" cy="50" r="45" />
            </svg>
          </div>
        )}
        {!isFetching && <div className="bpce-icon-font find"></div>}
      </div>
    </div>
  );
}
