import React, { useRef } from 'react';
import '../../../assets/scss/chip/src/index.scss';
import '../../../assets/scss/search-field/src/index.scss';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

export default function InputFilter({
  label,
  setFilter,
  name,
  isFilterOpen,
  setIsFilterOpen,
  width = 230
}) {
  const maxibulle = useRef();
  const inputRef = useRef();
  const buttonRef = useRef();
  const currentFilter = useRef();

  function openMaxibulle() {
    buttonRef.current.classList.toggle('bpce-chip-active');
    buttonRef.current.setAttribute('aria-expanded', 'true');
    maxibulle.current.setAttribute('data-show', '');
    inputRef.current.focus();
    setIsFilterOpen(true);
  }

  function closeMaxibulle() {
    if (buttonRef.current.classList.contains('bpce-chip-active')) {
      buttonRef.current.classList.remove('bpce-chip-active');
      buttonRef.current.setAttribute('aria-expanded', 'false');
      maxibulle.current.removeAttribute('data-show');
      setIsFilterOpen(false);
    }
  }

  function eraseInput() {
    inputRef.current.value = '';
    setFilter(name, '');
    buttonRef.current.classList.remove('bpce-chip-selected');
    closeMaxibulle();
  }

  useOnClickOutside(maxibulle, buttonRef, () => {
    closeMaxibulle();
  });

  return (
    <div ref={currentFilter}>
      <button
        ref={buttonRef}
        id="chipActionPanel"
        className="bpce-chip"
        aria-describedby="maxibulle-1"
        aria-expanded="false"
        onClick={() => {
          if (buttonRef.current.classList.contains('bpce-chip-active')) {
            closeMaxibulle();
          } else if (isFilterOpen === false) {
            openMaxibulle();
          }
        }}
      >
        <span className="bpce-chip-label">{label}</span>
      </button>
      <div
        id="maxibulle-1"
        className="bpce-maxibulle"
        role="tooltip"
        placement="bottom-end"
        ref={maxibulle}
        style={{ width: `${width + 54}px` }}
      >
        <div className="bpce-maxibulle-content">
          <div className="bpce-search-field">
            <span className="bpce-search-field-find bpce-icon-font find" aria-hidden="true"></span>
            <input
              ref={inputRef}
              className="bpce-search-field-input"
              type="text"
              style={{ width: `${width}px` }}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  eraseInput();
                  closeMaxibulle();
                }
              }}
              onChange={(e) => {
                if (
                  e.target.value.length > 0 &&
                  !buttonRef.current.classList.contains('bpce-chip-selected')
                ) {
                  buttonRef.current.classList.toggle('bpce-chip-selected');
                } else if (e.target.value.length === 0) {
                  buttonRef.current.classList.remove('bpce-chip-selected');
                }

                setFilter(name, e.target.value);
              }}
            />
            <button
              className="bpce-search-field-erase"
              onClick={() => {
                eraseInput();
              }}
            >
              <span className="bpce-icon-font erase" role="img" aria-label="Vider le champ"></span>
            </button>
          </div>
        </div>
        <span className="bpce-maxibulle-arrow" id="arrow"></span>
      </div>
    </div>
  );
}
